

import axios from "axios";

//const domain = "http://38.242.227.244:2020";
//const domain = "https://portalapi.biadjo.com:5003";
//const domain="https://admin.bala-app.com";
//const domain="https://localhost:5003/";
//const domain="https://api.egaarabians.com/";
//const domain="http://207.180.213.97:85";
//const domain="http://38.242.227.244:1213";

//const domain="https://localhost:5003/";
const domain = 'https://server.ivalleytraining.com/';

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("AccessToken");

export default axios.create({
  baseURL: domain
  // headers: {
  //     Authorization: "Bearer " + localStorage.getItem("AccessToken")
  //   }
});
