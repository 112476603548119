// en, de, fr, pt
export default {
  en: {
    Descraption:"Descraption",
    CancelRequest:"Reuest Cancel",
    cantaccept:"You can't accept this request the wallet balanced is lees the request anmount",
    SuccessCancelRequest:"Reuest Cancel Successfully",
    DriverWallet: "Driver Wallet",
    ClientWallet:"Client Wallet",
    ClientMoneyTransferRequests:"Client Money Transfer Requests",
    CanceledTrips:"Canceled Trips",
    UserDeletedRequests:"User Deleted Requests",
    DriverBalanceRequest:"Driver Balance Request",
    Deposit:"Deposit",
    Transfare:"Transfer",
    DriverMoneyTransferRequests:"Driver Money Transfer Requests",
    ApproveRequest: "Approve Request",
    SuccessfullyApproved: "Request Approved Successfully",
    IsRecivedCach:"Is Cash receipt",
    IsRecivedWithPaymentGetaway:"Is Recived With Payment Getaway",
    PaymentGetWay:"Payment GateWay",
    UserBalanceRequest:"User Balance Request",
    RequestDate:"Request Date",
    DateApproved:"Date Approved",
    CurrencyGuideSetting: "Currency Guide Setting",
    CurrencyGuide: "Currency Guide",
    Cars: "Cars",
    Type: "Type",
    VehiclesAxles: "Vehicles Axles",
    AddNew: "Add New",
    ShapeName: "Shape Name",
    VehiclesShape: "Vehicle Shape",
    CustomizeCustomers: "Customize Customers",
    WithdrawalPhone: "Customer With drawal Phone",
    PhoneForDeposit: "Customer Phone For Deposit",
    CustomizeDrivers: "Customize Drivers",
    CustomizeFleets: "Customize Fleets",
    CustomizeRemittances: "Customize Remittances",
    CurrencyName: "Currency Name",
    CurrencyCode: "Currency Code",
    ExchangeRate: "Exchange Rate",
    CurrencyGuideSetting: "Currency Guide Setting",
    CurrencyGuide: "Currency Guide",
    Cars: "View available cars",
    Type: "Type",
    VehiclesAxles: "Vehicles Axles",
    //AddNew:"Add New",
    ShapeName: "Shape Name",
    VehiclesShape: "Vehicle Shape",
    PersonalIDExp: "Personal ID Exp Date",
    VehiclesType: "Vehicles Type",
    DriverLicenseExp: "Driver License Exp Date",
    CarRegistrationExp: "Car Registration Exp Date",
    DocumentName: "Document Name",
    DeviceInfo: "Device Info",
    DeviceModel: "Device Model",
    DeviceType: "Device Type",
    Manufacture: "Manufacture",
    PlatformVer: "Platform Version",
    VersionCode: "Version Code",
    VersionName: "Version Number",
    UploadDocuments: "Upload Documents",
    reset: "Clear App Data",
    ExpiredDocs: "Expired Docs",
    ExpiredDocsDate: "Documents Expire Dates",
    AcceptOrRejectOffer: "Accept Or Reject Offer",
    NowYouCan: "Now you can",
    NewUpdates: "New Updates",
    ChangeUserAccountToCompany: "Change from individual to company",
    InvalidPhoneNumber: "Invalid Phone Number",
    RegisterDriver: "Register Driver",
    RegisterFleetCompany: "Register Fleet Company",
    RegisterClientCompany: "Register Client Company",
    PleaseAcceptTermsAndConditions:
      "Please accept terms and conditions to request a Trip",
    SendToDrivers: "Send to all Drivers",
    EnableLocationTitle: "Location service",
    EnableLocationMessage:
      "Please enable the location service (GPS) so you can use R2 app.",
    Loading: "Loading...",
    Message: "Message",
    LocationErrorTitle: "Location information",
    LocationErrorMessage:
      "Sorry we could not get your location information, Please retry again.",
    EnableInternetMessage:
      "Please enable the internet service so you can use R2 app.",
    EnableInternetTitle: "Internet Service",
    LoadingErrorMessage:
      "Sorry something went wrong while loading the application, Please retry again.",
    ServiceErrorMessage:
      "Sorry, The service is not available in your country for now.",
    LoadingErrorTitle: "Loading error",
    RequestBiadjo: "Request Trip",
    SO1: "New",
    SO2: "Offer Sent",
    SO3: "Offer Rejected",
    SO4: "Offer Accepted",
    CS1: "New",
    CS2: "In Progress",
    CS3: "Done",

    PS1: "Paid",
    PS2: "Unpaid",
    PS3: "Postponed",
    BS1: "New Request",
    BS2: "Waiting For Offers",
    BS3: "Selecting Offer",
    BS4: "Selected",
    BS5: "In Progress",
    BS6: "Arrived to Client",
    BS7: "Loading Cargo",
    BS8: "In the Way to the Destination",
    BS9: "Arrived to the Destination",
    BS10: "Unloading Cargo",
    BS11: "Done",
    BS12: "No Driver Found",
    BS13: "Canceled",
    BS14: "Closed",
    BS15: "Reported Accident",
    BS16: "Closed with Accident",

    DPS1: "Paid",
    DPS2: "Unpaid",
    DPS3: "Pawned",

    U1: "KG",
    U2: "TON",

    AS1: "Active",
    AS2: "Not Active",
    AS3: "On Hold",
    AS4: "Suspended",
    AS5: "Documents Uploading",
    AS6: "Waiting for Data Verification",
    DS1: "Not Uploaded",
    DS2: "Uploaded",
    DS3: "Accepted",
    DS4: "Rejected",
    TT1: "Light Duty Truck",
    TT2: "Medium 4 Meter Duty Truck",
    TT3: "Heavy 6 Meter Duty Truck",
    TT4: "Trailer Truck",
    TT5: "Semi-Trailer Truck",
    TT6: "Flatbed Truck",
    TT7: "Light Duty Box Truck",
    TT8: "Medium 4 Meter Duty Box Truck",
    TT9: "Heavy 6 Meter Duty Box Truck",
    TT10: "Semi-Trailer Box Truck",
    TT11: "Light Refrigerated  Truck",
    TT12: "Medium 4 Meter Refrigerated  Truck",
    TT13: "Heavy 6 Meter Refrigerated  Truck",
    TT14: "Semi-Trailer Refrigerated  Truck",
    TT15: "Wood Transport Truck",
    TT16: "Cars Transport Truck",
    TT17: "Car Towing Truck",
    TT23: "Suzuki Open Truck",
    TT24: "Suzuki Box Truck",
    AT0: "Individual",
    AT1: "Admin",
    AT2: "Employee",
    AT3: "Company Owner",

    TO0: "Dump Truck",

    AAT0: "Super Admin",
    AAT1: "IT",
    AAT2: "Technical Support",
    AAT3: "Admin",
    AAT4: "Investor",
    AAT5: "Sales",
    AAT6: "Account Manager",
    AAT7: "Call Center",
    AAT8: "Accountant",
    AAT9: "Manager Level 1",
    AAT10: "Manager Level 2",
    AAT11: "Manager Level 3",
    AAT12: "Manager Level 4",
    AAT13: "Manager Level 5",
    AAT14: "Employee",

    P1: "Wallet",
    P2: "Cash",
    P3: "Credit or Dept Card",
    P4: "Check",
    P5: "Bank Transfer",

    T1: "Deduction from the Wallet",
    T2: "Add credit to the wallet",
    T3: "Bank Transfer",
    T4: "Deduction from Credit Card",
    T5: "Refund",
    T6: "Cash",

    OpenBedTruck: "Open Bed Truck",
    ClosedBoxTruck: "Closed Box Truck",
    RefrigeratorTruck: "Refrigerated Truck",
    SpecialTruck: "Special Truck",
    SelectSize: "Select a Size",
    WithDumpster: "Dump Truck",
    PickupLocation: "Pickup Location",
    DestinationLocation: "Destination Location",
    CargoTitle: "Cargo Type",
    CargoDescription: "Cargo Description",
    EstimatedValue: "Cargo Estimated Value",
    CargoWeight: "Cargo Weight",
    TripDetails: "Trip Details",
    CargoInsurance: "Cargo Insurance",
    ResendBiadjoRequest: "Resend Trip Request",
    LightSize: "Light",
    MediumSize: "Medium 4 Meter",
    HeavySize: "Heavy 6 Meter",
    TrailerSize: "Trailer",
    SemiSize: "Semi-Trailer",
    FlatbedSize: "Flatbed",
    WoodSize: "Wood",
    CarsSize: "Cars",
    TowingSizes: "Towing",

    OpenTruckSized:
      "Light, Medium 4 Meter, Heavy 6 Meter, \nTrailer, Semi-Trailer",
    ClosedTruckSizes: "Light, Medium 4 Meter, \nHeavy 6 Meter, Semi-Trailer",
    RefrigeratedSizes: "Light, Medium 4 Meter, \nHeavy 6 Meter, Semi-Trailer",
    SpecialSizes: "Flatbed, Wood, \nCars, Towing",

    TripSchedule: "Trip Schedule",
    Create: "Create",
    InvaliType: "Invalid Type",
    InvalidImageType:
      "Invalid image type please make sure image is (png-jpg-tiff)",
    DriverInfo: "Driver Information",
    UserInfo: "User Information",
    LessThanOrEqual: "Less than or equal",
    GreaterThanOrEqual: "Greater than or equal",
    BankAccountInfo: "Bank Account Info",
    ApplyBankInfoToAllDrivers: "Apply bank info to all drivers",
    VehicleInfo: "Vehicle Information",
    FullName: "Full Name",
    Home: "Home",
    Price: "Price",
    PaymentID: "Payment ID",
    EG_Currency: "EGP",
    LY_Currency: "LYD",
    CZ_Currency: "EUR",
    US_Currency: "USD",
    GB_Currency: "GBP",
    FleetCompanies: "Fleet Companies",
    UserAccount: "User Account",
    Driver: "Driver",
    Drivers: "Drivers",
    User: "User",
    Clients: "Clients",
    BiadjoTitle: "R2 Admin Portal",
    BiadjoNumber: "Trip number",
    AssignBiadjo: "Assign to Trip",
    Setting: "Settings",
    Biadjo: "Trip",
    PaymentGateway: "Payment Gateway",
    Scheduled: "Scheduled",
    ScheduleConflict: "Schedule Conflict",
    Edit: "Edit",
    Back: "Back",
    FirstName: "First Name",
    LastName: "Last Name",
    PhoneNumber: "Phone Number",
    DriverBalance: "Driver Balance",
    AccountStatus: "Account Status",
    VacationMode: "Vacation Mode",
    VehicleType: "Vehicle Type",
    VehicleOption: "Vehicle Option",
    UserBalance: "User Balance",
    VATREQ: "Tax bill",
    KMBiadjo: "KM Trip",
    AddDriverPriceandAssignDrivertoKMBiadjo: "Assign Driver with KM Price",
    DriverPrice: "Driver Price",
    trueBool: "Yes",
    falseBool: "No",
    BiadjoAcceptedKMPrice: "Accepted Trip with KM Price",
    KMOffer: "KM Offer",
    showNoDataAvailable: "No data available... Please try again",
    NoNotesAvailable: "No notes available",
    Error: "Error",
    SorryYouAccountisNotActive: "Sorry..You account is not active",
    WelcomeMsg: "Welcome back, Please login to your account.",
    Update: "Update",
    showError: "Something went wrong... Please try again",
    showUpdate: "Record updated successfully...",
    ResetPassword: "Reset Password",
    showCheckEmail: "Kindly check you email for new password...",
    showPasswordChanged: "Password changed...",
    ConflictedBiadjo: "Conflicted Trip",
    BodyConflictedBiadjo:
      "You have New Conflicted Trips Please Resolve it As Soon As Possible there count is ",
    Dashboard: "Dashboard",
    NoDriverFound: "Trips without drivers",
    WaitingForVerficationDrivers: "Waiting for verfication",
    Height: "Height",
    Length: "Length",
    Width: "Width",
    Country: "Country",
    NewCity: "New City",
    CustomerId: "Customer ID",
    StartCountry: "Start Country",
    StartCountryCode: "Start Country Code",
    EndCountry: "End Country",
    EndCountryCode: "End Country Code",
    FindNearByDrivers: "Find near by drivers",
    Email: "Email",
    Calendar: "Calendar",
    Shop: "Shop",
    Cart: "Cart",
    WishList: "Wish List",
    Checkout: "Checkout",
    UI: "UI",
    Colors: "Colors",
    Card: "Card",
    Basic: "Basic",
    Table: "Table",
    Components: "Components",
    Alert: "Alert",
    Breadcrumb: "Breadcrumb",
    Collapse: "Collapse",
    Progress: "Progress",
    Upload: "Upload",
    Select: "Select",
    Switch: "Switch",
    BiadjMap: "Trip Map",
    Radio: "Radio",
    Input: "Input",
    Pages: "Pages",
    Authentication: "Authentication",
    Login: "Login",
    Register: "Register",
    ForgotPassword: "Forgot Password ?",
    LockScreen: "Lock Screen",
    Miscellaneous: "Miscellaneous",
    ComingSoon: "Coming Soon",
    404: "404",
    500: "500",
    NotAuthorized: "Not Authorized",
    Maintenance: "Maintenance",
    Filters: "Filters",
    Profile: "Profile",
    Invoice: "Invoice",
    FAQ: "FAQ",
    Search: "Search",
    CreateAccount: "Create Account",
    FillTheForm: "Fill the below form to create a new account",
    UpdateImage: "Update Image",
    AssignToMe: "Assign to me",
    KnowledgeBase: "Knowledge base",
    ChartsAndMaps: "Charts & Maps",
    Charts: "Charts",
    GoogleMap: "Google Map",
    Extensions: "Extensions",
    DragAndDrop: "Drag & Drop",
    Datepicker: "Datepicker",
    DatetimePicker: "Datetime Picker",
    AccessControl: "Access Control",
    I18n: "I18n",
    Carousel: "Carousel",
    Clipboard: "Clipboard",
    ContextMenu: "Context Menu",
    StarRatings: "Star Ratings",
    Autocomplete: "Autocomplete",
    Tree: "Tree",
    Schedual: "Schedual",
    ScheduledBiadjo: "Scheduled Trip",
    TransactionsHistory: "Transaction History",
    AddCompanyUser: "Add Company User",
    CompanyProfile: "Company profile",
    AddUser: "Add user",
    ViewUsers: "View users",
    CompanyTrips: "Company trips",
    SwitchIndividualToCompany: "Switch to company",
    Import: "Import",
    Export: "Export",
    ExportSelected: "Export Selected",
    Others: "Others",
    DisabledMenu: "Disabled Menu",
    Support: "Support",
    Documentation: "Documentation",
    RaiseSupport: "Raise Support",
    demoTitle: "Card Title",
    Planning: "Planning",
    AnnualPlan: "Annual Plan",
    Language: "Language",
    Facility: "Facility",
    ClosingDate: "Closing Date",
    CreationDate: "Creation Date",
    BiadjosPerStatus: "Trips Per Status",
    PaymentStatus: "Payment Status",
    DriverPaymentStatus: "Driver payment status",
    QuestionType: "Question Type",
    QuestionLevel: "Question Level",
    QuestionBank: "Question Bank",
    Rating: "Rating",
    Cancel: "Cancel",
    CancelBiadjo: "Cancel Trip",
    RememberMe: "Remember Me",
    Actions: "Actions",
    Status: "Status",
    ContactType: "Contact Type",
    Customer: "Customer",
    DriverID: "Driver ID",
    BiadjoA: "Trip A",
    UserManagement: "User Management",
    Users: "Users",
    YearSummary: "Year Summary",
    CourseClass: "Course Classes",
    Question: "Question Management",
    BiadjoB: "Trip B",
    Biadjo_A_Schedule: "Trip A schedule",
    Biadjo_B_Schedule: "Trip B schedule",
    BiadjoStatus: "Biajdjo status",
    UserID: "User ID",
    RegistrationDate: "Registration Date",
    CountryCode: "Country Code",
    PaymentMethod: "Payment Method",
    Date: "Date",

    NumberOfStops: "Number Of Stops",

    CargoType: "Cargo Type ",
    CargoValue: "Cargo Value",
    Value: "Value",
    SaveChanges: "Save Changes",
    TripDistance: "Trip Distance",
    TripDuration: "Trip Duration",
    StartLocation: "Start Location ",
    StartPlaceId: "Start Place ID",
    StartAddress: "Start Address",
    EndAddress: "End Address",
    EndLocation: "End Location",
    EndPlaceId: "End PlaceId",
    ViewDriverDetails: "View driver details",
    ViewUserDetails: "View user details",
    ViewSubmittedOrders: "Submitted Orders",
    AssignBiadjoToDriver: "Assign Trip to driver",
    ViewOffers: "View Offers",
    NumberOfOffers: "Number of offers",
    Offer: "Offer",
    Duration: "Duration",
    Distance: "Distance",
    Close: "Close",
    CloseBiadjo: "Close Trip",
    ModefideDate: "Modefide Date",
    AssignToDriver: "Assign to driver",
    Assign: "Assign",
    Time_Deff: "Time Difference",
    AssignedUserID: "Assigned user ID",
    AssignedUserName: "Assigned user name",
    DriverUserName:"Driver User Name",
    Name: "The name",
    BiadjoDetails: "Trip Details",
    CreditLimit: "Credit Limit",
    PleaseEnterAllData: "Please enter all data",
    AddInsurance: "Add Insurance",
    InsurancePolicyNo: "Insurance Policy Number",
    SelectLocation: "Select Location",
    InsuranceWarning:
      "By canceling the insurance, you are solely responsible for the safety of the cargo during loading, unloading and transport",

    FreeTrips: "Free Trips",
    AccountType: "Account Type",
    Password: "Password",
    Confirm: "Confirm",
    ConfirmAndPrint: "Confirm & Print",
    BirthDate: "Birth Date",
    DriverLicense: "Driver License",
    NationalID: "National ID",
    Documents: "Documents",
    CompletedTrips: "Completed Trips",
    LicensePlate: "License Plate",
    BankAddress: "Bank Address",
    SWIFT: "SWIFT",
    City: "City",
    StartCity: "Start City",
    Balance: "Balance",
    EndCity: "End City",
    StartState: "Start State",
    EndState: "End State",
    Insurance: "Insurance",
    InsuranceValue: "Insurance Value",
    AccountNumber: "Account Number",
    BranchName: "Branch Name",
    BankName: "Bank Name",
    vacationMode: "Vacation Mode",
    DriverNo: "Driver No",
    VehicleMake: "Vehicle Make",
    VehicleModel: "Vehicle Model",
    VehicleColor: "Vehicle Color",
    EditUser: "Edit User",
    ClientInformation: "Client Information",
    SubmittedOrderNumber: "Submitted order number",
    Save: "Save",
    ConfirmChange: "Change Confirmation",
    YouAreAboutToChangeThis: "You are about to change",
    YouAreAboutToSendOrder: "You are about to send order",

    YouAreAboutToChangeDriverStatus:
      "You are about to change driver account status",
    YouAreAboutToChangeDriverData: "You are about to change driver data",
    YouAreAboutToAssignDriver:
      "You are about to assign this driver to a driver",
    Change: "Change",
    YouAreAboutToResetPassswordByEmail:
      "You are about to reset passsword by email",
    ConflictedBiadjos: "Conflicted Trips",
    YouAreAboutToResetPasssword: "You are about to reset passsword",
    YouAreAboutToUpdateBankInfo: "You are about to update bank information",
    AddBalance: "Add Balance",
    Reports: "Reports",
    SubmitOffer: "Submit Offer",
    OfferWillSendWithValue: "Offer will send with value",
    RegisterCreatedSuccessfuly: "Register created Successfuly",
    AlreadyExist: "Already Exist",
    ThisUserAlreadyExist: "This user already exist",
    BiadjosHistory: "Trips History",
    ScheduledBiadjos: "Scheduled Trips",
    BiadjoHistory: "Trip History",
    UnpaidBiadjos: "Unpaid Trips",
    ClosingDateFrom: "Closing Date From",
    ClosingDateTo: "Closing Date To",
    DeductBalance: "Deduct Balance",
    PrintInvoice: "Print Invoice",
    printpayment: "Print Payment",
    UnclosedBiadjos: "Unclosed Trips",
    BiadjosNeedInsurance: "Insurance",
    OffersWaitingForApproval: "Offers waiting for approval",
    UnStartedBiadjos: "UnStarted Trips",
    Reset: "Reset",
    ClosedBiadjos: "Closed Trips",
    DriverNotFound: "Driver Not Found",
    ClientNotFound: "Client Not Found",
    UserNotFound: "User Not Found",
    PaymentDetails: "Payment Details",
    TransactionsHistoryOfClient: "Transactions history of client",
    ViewTransactionsHistory: "View transactions history",
    UnPaidDriverList: "Unpaid driver list",
    RecieptNumber: "Reciept Number",
    TransactionType: "Transaction Type",
    CommercialRegistrationID: "Commercial registration ID",
    TransactionNumber: "Transaction Number",
    UnPaidBiadjosCount: "Unpaid Trips count",
    YouAreAboutToPay: "You are about to pay",
    PaiedBiadjosTotal: "Total paid Trip",
    PaiedBiadjosCount: "Total paid Trip count",
    PaiedBiadjosList: "Paid Trip List",
    UnPaiedBiadjosTotal: "Total unpaid Trip list",
    UnPaiedBiadjosCount: "Total unpaid Trip count",
    UnPaiedBiadjosList: "Unpaid Trip list",
    PaiedIncomeTax: "Paid income tax",
    FollowDriver: "Follow Driver",
    TotalPrice: "Total Price",
    Total: "Total",
    DriverTotalPayment: "Total driver payment",
    SelectedBiadjoNo: "Selected Trip numbers",
    FleetCompaniesList: "Fleet companies list",
    CompanyName: "Company Name",
    ZibCode: "Zib Code",
    CompanyDrivers: "Company Drivers",
    CompanyDetails: "Company Details",
    ShowCompanyDetails: "Show company details",
    CompanyUsers: "Company Users",
    CompanyUser: "Company User",
    TaxID: "Tax ID",
    Address: "Address",
    ZipCode: "Zip Code",
    somethingwentwrong: "Something went wrong..!",
    DriverViewPrice: "Allow Driver to View Trip Price",
    DriverViewRequests: "Allow Driver to View Trip Requests",
    DriverSendOffer: "Allow Driver to Send Offers",
    DriverViewBalance: "Allow Driver to View Balance",
    DriverViewTransactionHistory: "Allow Driver to View Transactions History",
    CompanyPermissions: "Company Permissions",
    YouAreAboutToUpdateBankInfoForAllDrivers:
      "You are about to update bank info for all drivers",
    YouAreAboutToChangeThisUserAccountType:
      "You are about to change this user account type",
    ViewDrivers: "View drivers",
    AddDriver: "Add driver",
    ALL: "All",
    CompanyWalletBalance: "Wallet Balance",
    RemoveDriver: "Remove Driver",
    Remove: "Remove",
    FAT1: "Admin",
    FAT2: "Employee",
    Id: "Id",
    CompanyInformation: "Company Information",
    State: "State",
    CompanyInfo: "Company Information",
    CompanyID: "Company ID",
    WorkingField:"Working Field",
    PersonalPicture: "Personal Picture",
    PersonalIDFront: "Personal ID Front",
    PersonalIDBack: "Personal ID Back",
    Passport: "Passport",
    DriverLicenseFront: "Driver License Front",
    DriverLicenseback: "Driver License back",
    CarRegistration: "Car Registration",
    CarRegistrationBack: "Car Registration Back",
    LicensePlateBack: "License Plate Back",
    LicensePlateFront: "License Plate Front",
    CarPicture: "Car Picture",
    CarInsuranceDoc: "Car Insurance Doc",
    CargoInsuranceDoc: "Cargo Insurance Doc",
    BackgroundCheck: "Background Check",
    DrugTest: "Drug Test",

    PersonalPictureStatus: "Personal Picture Status",
    PersonalIDFrontStatus: "Personal ID Front Status",
    PersonalIDBackStatus: "Personal ID Back Status",
    PassportStatus: "Passport Status",
    DriverLicenseFrontStatus: "Driver License Front Status",
    DriverLicensebackStatus: "Driver License back Status",
    CarRegistrationStatus: "Car Registration Status",
    CarRegistrationBackStatus: "Car Registration Back Status",
    LicensePlateBackStatus: "License Plate Back Status",
    LicensePlateFrontStatus: "License Plate Front Status",
    CarPictureStatus: "Car Picture Status",
    CarInsuranceDocStatus: "Car Insurance Doc Status",
    CargoInsuranceDocStatus: "Cargo Insurance Doc Status",
    BackgroundCheckStatus: "Background Check Status",
    DrugTestStatus: "Drug Test Status",
    YouAreAboutToChangeDocumentStatus:
      "You are about to change document status",

    FollowOnMap: "Follow On Map",
    Amount: "Amount",
    TransactionNo: "Transaction Number",
    ReceiptNumber: "Receipt Number",
    TransactionReferenceForCreditDebitCard:
      "Credit/Debit card transaction reference",
    ChequeNumber: "Cheque Number",
    BankTransferReference: "Bank transfer reference",
    TransactionInfo: "Transaction Information",
    NewBalance: "New Balance",
    recieptNo: "Reciept Number",
    AccidentReport: "Accident Report",
    ClientId: "Client ID",
    AdminUserId: "AdminUser ID",
    CloseAccidentBiadjo: "Close accident Trip",
    CloseAccidentBiadjoNote:
      "Please make sure accident pictures and documents have been uploaded",
    YouAreAboutToCloseBiadjo: "You are about to close Trip",
    ReportAccident: "Report Accident",
    ConfirmClose: "Confirm Close",
    Submit: "Submit",
    AccidentPictures: "Accident Pictures",
    AddNote: "Add Note",
    ConfirmRemove: "Confirm Remove",
    YouAreAboutToRemoveDriver: "You are about to remove this driver",
    Agree: "Agree",
    StatusLogHistory: "Status Log History",
    DateTime: "DATETIME",
    LogNumber: "Log Number",
    SuccessAddNote: "Note added successfully",
    AreYouSureYouWantToApproveRequest: "Are You Sure You Want To Approve Request",
    AvailableSizes: "Available Sizes",
    Category: "Category",
    Preview: "Preview",

    YouAreAboutToChangeThisClientData:
      "You are about to change this client data",
    YouAreAboutToChangeThisClientStatus:
      "You are about to change this client status",
    VAT: "VAT",
    SessionExpired: "Session expired, Please login again...",
    Logout: "Logout",
    YourCancelationFeesIs: "Your cancelation fees is ",
    THereIsNoCancelationFees: "There is no cancelation fees",
    InvalidEmailPleasetryagain: "Invalid email... Please try again..!",
    InvalidPasswordPleasetryagain: "Invalid password... Please try again..!",
    Deletedrecord: "Deleted record",
    Theselectedrecordwassuccessfullydeleted:
      "The selected record was successfully deleted",
    Areyousureyouwantdeletethisrecord:
      "Are you sure you want delete this record?",
    LoginAttempt: "Login Attempt",
    Youarealreadyloggedin: "You are already logged in!",
    BiadjoSchedualeForWeek: "Weekly Scheduled Trip",
    IncomePerDayforWeek: "Weekly Income Per Day",
    DriversPerTruckType: "Drivers Per Truck Type",

    TrucksTypes: "Trucks Types",
    BiadjoSchedualePerStatusForToday: "Schedule Trip For Today",
    DriversPerStatus: "Drivers Per Status",
    ClosedBiadojsPerDayForWeek: "Closed Trips Per Day For Week",
    UnClosedBiadojsPerDayForWeek: "Unclosed Trips Per Day For Week",
    BiadojsPerTruckTypeForWeek: "Trips Per Truck Type For Week",
    BiadojsPerDayForWeek: "Trips Per Day For Week",
    TodayBiadojsForTodayperStatus: "Today Trips Per Status",
    ToDayBiadojsPerTrucktype: "Today Trips Per Truck Type",
    WatingForVerificationDriversCount: "waiting For Verification Drivers Count",
    NONotifications: "Your notification list is empty",
    UnStartedBiadjosCount: "Unstarted Trips Count",
    UnInsuredBiadjosCount: "Uninsured Trips Count",
    NoDriverFoundBiadojsCount: "Trip No Driver Found Count",
    VehicleOwnerFullName: "Vehicle owner name",
    personalPicture: "Personal Picture",
    personalIDFront: "Personal ID Front",
    personalIDBack: "Personal ID Back",
    passport: "Passport",
    driverLicenseFront: "Driver License Front",
    driverLicenseback: "Driver License Back",
    carRegistration: "Car Registration",
    carRegistrationBack: "Car Registration Back",
    licensePlateBack: "License Plate Back",
    licensePlateFront: "License Plate Front",
    carPicture: "Car Picture",
    carInsuranceDoc: "Car Insurance Doc",
    cargoInsuranceDoc: "Cargo Insurance Doc",
    backgroundCheck: "Background Check",
    drugTest: "Drug Test",
    taxIDCard: "Tax ID card for vehicle Owner",
    YourSearchingCountry: "Your searching country is",
    ClientCompanyId: "Client Company ID",
    FleetCompanyId: "Fleet Company ID",
    Arabic: "Arabic",
    English: "English",
    Urdu: "Urdu",
    GenerateReport: "Generate Report",
    UnPaiedDriversDetails: "Unpaid Drivers Details",
    UnPaiedCompaniesDetails: "Unpaid Companies Details",
    Income: "Income",
    IncomeDetailed: "Income in details",
    IncomeReportSummary: "Income Summary Report",
    UnOfficialIncomeSummaryReport: "Un Official Income Summary Report",
    AddCity: "Add City",
    AddCountry: "Add Country",
    AddPhone: "Add Phone",
    AddState: "Add State",
    AddZibCode: "Add Zip Code",
    CommercialRegistrationNo: "Commercial Registration No",
    Currancy: "Currancy",
    CurrancyISOCode: "Currancy ISO Code",
    DriverTAXPercentage: "Driver TAX Percentage",
    InsurancePercentage: "Insurance Percentage",
    IntAddCity: "Int Add City",
    IntAddCountry: "Int Add Country",
    IntAddPhone: "Int Add Phone",
    IntAddState: "Int Add State",
    IntAddZibCode: "Int Add Zip Code",
    IntAddress: "Int Address",
    VATID: "VAT ID",
    VATPercentage: "VAT Percentage",
    DriverPercentage: "Driver Percentage",
    SupportPhoneNo: "Support Phone No",
    SupportEmail: "Support Email",
    UserInitialBalance: "User Initial Balance",
    UserInitialFreeTrips: "User Initial Free Trips",
    MaxUserCredit: "Max User Credit",
    DriverInitialFreeTrips: "Driver Initial Free Trips",
    DriverInitialBalance: "Driver Initial Balance",
    DriverInitialRating: "Driver Initial Rating",
    MaxDriverCredit: "Max Driver Credit",
    CancelationFeePercentage: "Cancelation Fee Percentage",
    IncorrectCode: "Incorrect Code",
    PleaseFillAllRequiredData: "Please fill all required data...",
    ChangeType: "Change Type",
    YouAreAlreadyloggedin: "You are already logged in!",
    Deactivate: "Deactivate",
    EditCompanyBankAccountInformation: "Edit Company Bank Account Information",
    CompanyTransactionHistory: "Company Transaction History",
    CompanyWallet: "Company Wallet",
    FollowBiadjoOnMap: "Follow Trip On Map",
    CompanySubmittedOrder: "Company Submitted Orders",
    DriverSubmittedOrders: "Driver Submitted Orders",
    EditCompanyUser: "Edit Company User",
    EditCompanyProfile: "Edit Company Profile",
    CompanyUserRegister: "Company User Register",
    CompanyUserProfile: "Company User Profile",
    UserPersonalDetails: "User Personal Details",
    DriverProfile: "Driver Profile",
    BankAccountEdit: "Bank Account Edit",
    DriverScheduledBiadjos: "Driver Scheduled Trips",
    DriverBiadjosHistory: "Driver Trips History",
    DriverLocation: "Driver Location",
    TransactionsHistoryOfDriver: "Transactions History Of Driver",
    ContactUs: "Contact Us",
    ScheduledBiadjosForCompany: "Scheduled Trips ",
    CompanyBiadjosHistory: "Trips history",
    DriverUnpaidBiadjos: "Driver Unpaid Trips",
    logout: "Force Logout",
    YouAreAboutTo: "You Are About To",
    IdleSessionLogout: "Idle Session Logout",
    BiadjoStatusLogHistory: "Trip Status Log History",
    ReportBiadjoAccident: "Report Trip Accident",
    BiadjoAccidentReport: "Trip Accident Report",
    DeductClientBalance: "Deduct Client Balance",
    DeductDriverBalance: "Deduct Driver Balance",
    AddClientBalance: "Add Client Balance",
    AddDriverBalance: "Add Driver Balance",
    UserDetails: "User Details",
    Details: "Details",
    UserSearch: "User Search",
    FleetDriver: "Fleet Driver",
    BiadjoHistoryOfClient: "Trip History Of Client",
    ScheduledBiadjoOfClient: "Scheduled Trip Of Client",
    ClientProfile: "Client Profile",
    TextCopiedSuccessfully: "Text copied successfully",
    ClientEdit: "Client profile Edit",
    DriverDocuments: "Driver Documents",
    DriverEdit: "Driver profile Edit",
    BankAccountInformation: "Bank Account Information",
    EditCompany: "Edit Company information",
    CompanyBankAccountInformation: "Company Bank Account Information",
    FleetCompaniesDetails: "Fleet Companies Details",
    FleetCompanyWalletBalance: "Fleet Company Wallet Balance",
    CompanySubmitedOrdersAndOffers: "Company Submited Orders And Offers",
    CompanyScheduledBiadjos: "Company Scheduled Trips",
    AddCompanyDriver: "Add Company Driver",
    FleetCompanyUserProfile: "Fleet Company User Profile",
    FleetCompanyUsers: "Fleet Company Users",
    BiadjosWithoutDrivers: "Trips With out Drivers",
    ConflictedDetails: "Conflicted Trip Details",
    ClientCompanyDetails: "Client Company Details",
    UpdateBiadjo: "Update Trip",
    ScheduleDetails: "Schedule Trip Details",
    BiadjoSearch: "Trip Search",
    CountryEdit: "Country Edit",
    "Contact-Us": "Contact US",
    AddNewState: "Add New State",
    ClientExpensesStatement: "Client expenses statement",
    StartDate: "Start Date",
    EndDate: "End Date",
    Flag: "Flag",
    Code: "Code",
    showDriverAddeddSuccessfuly: "driver addedd successfuly",
    Send: "Send",
    ClickHere: "Click here",
    In: "In",
    EG: "Egypt",
    LY: "Libya",
    NumberOfWorkers: "Required Number Of Workers",
    ThereISNumber: "Number of",
    CargoWorkersConfirmation: "Do you want to request workers to load goods?",

    DriversWaitingForVerfication: "Drivers waiting for verfication",
    EditOffer: "Edit Offer",
    ACHEGDriversReport: "ACH EG Drivers Report",
    ACHEGFleetsReportrepx: "ACH EG Fleets Report",
    SubmitOrder: "Submit Order",
    SubmitOrderToDriverOrFleet: "Submit order to driver or fleet",
    ClientsCompanyList: "Clients company list",
    clientCompanies: "Client companies",
    SubmitOfferToOrder: "Submit Offer to order",
    RegisterCompany: "Register Company",
    RegisterUser: "Register Client",
    CompanyPhoneNo: "Company Phone Number",
    CargoInsuranceConfirmation: "Would you like to add cargo insurance?",
    Next: "Next",
    TruckType: "Truck Type",
    CargoInfo: "Cargo information",
    TripSchedual: "Trip Schedual",
    SelectDestinationLocation: "Select Destination Location",
    Enteralocation: "Enter a location",
    TermsandConditions: "Terms and Conditions",
    TermsAndConditions: "Terms and Conditions.",
    Accept: "Accept",
    SelectPickupLocation: "Select Pickup Location",
    EditCountry: "Edit Country",
    Schedule: "Schedule",
    NumberOfTrips: "Number of stops",
    Trips: "Stops",
    TripNo: "Step Number :",
    PickupStopLocation: "pickup stop location ",
    Areyoushureyouwanttoacceptthisoffer:
      "Are you sure you want to accept this offer ?",
    Areyoushureyouwanttoremovethisoffer:
      "Are you sure you want to remove this offer ?",
    OffersForBiadjo: "Offers for Trip",
    NewBiadjoRequest: "New Trip Request",
    changeCountry: "Change Country",
    ReConfirm: "Reconfirm",
    GoToMob: "Navigate To (Mobile)",
    GoToWeb: "Navigate To (Web)",
    GoTo: "Navigate To Page",

    DriverWaitingForVerfication: "Driver Waiting For Verfication",
    BodyDriverWaitingForVerfication:
      "There is a Driver waiting for verfication with ID",

    NewBiadjo: "New Trip",
    BodyNewBiadjo: "there is a new Trip with ID",

    BiadjoWithoutDrivers: "Trip Without Drivers",
    BodyBiadjoWithoutDrivers: "there is a new Trip without Driver ID",

    BodyOffersWaitingForApproval:
      "there is a new offer waiting for approve or reject with ID",

    DoneBiadjos: "Done Trips",
    BodyDoneBiadjos:
      "there is a Trip done and waiting for Client to close it with ID",

    UnstartedBiadjos: "Unstarted Trips",
    BodyUnstartedBiadjos: "there is unstarted Trip with ID",
    BankAccountInformationScreen: "Bank Account Information",
    EditProfileScreen: "Edit Profile",
    ChangeLanguageScreen: "Change Language",
    ChangePhoneScreen: "Change Phone Number",
    ContactUsScreen: "Contact US",
    SupportScreen: "Support Screen",
    WalletHistoryScreen: "Transctions History",
    WalletSavedPaymentScreen: "Saved Payment Methods",
    Notification: "Notification",
    PleaseAllowNotification:
      "Please allow notifications to recieve new updates",
    BodyOfferAccepted: "Your Trip offer is accepted with ID",
    RecieveBiadjoRequest: "Recieve Trip request",
    bodyRecieveBiadjoRequest: "New Trip request with ID ",
    BodyNewOffer: "You have received new offer for Trip number",
    YouAreAboutToChangeUserStatus: "You are about to change user status",
    NewOffer: "New Offer",
    ClickRefresh: "Click here to Refresh or press ALT+L",
    ViewAllNotifications: "View All Notifications",
    AddDiscount: "Add discount",
    ResetID: "ResetID",
    YouAreAboutToPayThisBiadjo: "You are about to Pay this Trip",
    YouAreAboutToChangeBiadjoData: "You are about to change Trip data",
    deleteAllNotification: "Delete All",
    MarkAsReadAll: "Mark all as read ",
    AddRoadAndScaleCharges: "Add road and scale charges",
    RoadAndScaleCharges: "road and scale charges",
    DuplicateBiadjo: "Duplicate Trip",
    LastSeen: "Last Seen",
    OnlineDriversForTheLastWeek: "Online Drivers For Last Week",
    OnlineDriversPerTruckType: "Online Drivers Per Truck Type",
    AddFixedPriceOffer: "Add Fixed Price",
    ShowOffersWithNoRestrict: "Show Offers With No Restrict",
    GeneralSetting: "General Setting",
    StateNameAr: "Arabic State Name",
    StateNameEn: "English State Name",
    CityNameAr: "Arabic City Name",
    CityNameEn: "English City Name",
    AddCity: "Add City",
    VehiclesSetting: "Vehicles Setting",
    VehiclesCategory: "Vehicles Category",
    WithdrawalAndPayment: "Withdrawal And Payment",
    PaymentWayName: "Payment Way Name",
    OperationNumber: "Operation Number",
    OperationSide: "Operation Side",
    OperationStatus: "Operation Status",
    Item: "Item",
    Confirm: "Confirm",
  },

  ur:{
    Descraption:"Descraption",
    CancelRequest:"Reuest Cancel",
    cantaccept:"You can't accept this request the wallet balanced is lees the request anmount",
    SuccessCancelRequest:"Reuest Cancel Successfully",
    DriverWallet:"Driver Wallet",
    ClientWallet:"Client Wallet",
    ClientMoneyTransferRequests:"Client Money Transfer Requests",
    CanceledTrips:"منسوخ شدہ دورے",
    DriverBalanceRequest:"Driver Balance Request",
    UserDeletedRequests:"User Deleted Requests",
    Deposit:"جمع",
    Transfare:"منتقلی",
    DriverMoneyTransferRequests:"ڈرائیور کی رقم کی منتقلی کی درخواستیں۔",
    ApproveRequest: "درخواست منظور کریں۔",
    SuccessfullyApproved: "درخواست کامیابی کے ساتھ منظور ہو گئی۔",
    IsRecivedCach:"کیش کی رسید",
    IsRecivedWithPaymentGetaway:"ادائیگی کے گیٹ وے کے ساتھ موصول ہوتا ہے",
    PaymentGetWay:"پیسے ادا کرنے کا طریقہ",
    UserBalanceRequest:"صارف کے بیلنس کی درخواست",
    RequestDate:"درخواست کی تاریخ",
    DateApproved:"منظوری کی تاریخ",
    Item: "آئٹم",
    OperationStatus: "آپریشن کی حیثیت",
    OperationSide: "آپریشن سائیڈ",
    OperationNumber: "آپریشن نمبر",
    PaymentWayName: "ادائیگی کے طریقے کا نام",
    CustomizeCustomers: "گاہکوں کے بارے میں",
    WithdrawalPhone: "ریفل فون نمبر",
    PhoneForDeposit: "فون نمبر جمع کروائیں۔",
    CustomizeDrivers: "ڈرائیوروں کے حوالے سے۔",
    CustomizeFleets: "بیڑے کے مالکان سے متعلق۔",
    CustomizeRemittances: "ترسیلات زر کو حسب ضرورت بنائیں",
    CurrencyName: "کرنسی",
    CurrencyCode: "کوڈ",
    ExchangeRate: "زر مبادلہ کی شرح",
    CurrencyGuideSetting: "کرنسی گائیڈ کی ترتیب",
    CurrencyGuide: "کرنسی گائیڈ",
    Cars: "دستیاب کاریں دیکھیں",
    Type: "قسم",
    VehiclesAxles: "کار کے محور",
    AddNew: "نیا شامل کریں",
    ShapeName: "نام کی شکل دیں۔",
    VehiclesShape: "گاڑی کی شکل",
    VehiclesSetting: "گاڑیوں کی ترتیب",
    VehiclesType: "گاڑیوں کی قسم",
    VehiclesCategory: "گاڑیوں کا زمرہ",
    PersonalIDExp: "ذاتی ID کی میعاد ختم ہونے کی تاریخ",
    StateNameAr: "عربی ریاست کا نام",
    StateNameEn: "انگریزی ریاست کا نام",
    CityNameAr: "عربی شہر کا نام",
    CityNameEn: " انگریزی شہر کا نام",
    DriverLicenseExp: "ڈرائیور لائسنس کی میعاد ختم ہونے کی تاریخ",
    CarRegistrationExp: "کار کی رجسٹریشن کی تاریخ",
    DocumentName: "دستاویز کا نام",
    DeviceInfo: "آلہ کی معلومات",
    DeviceModel: "ڈیوائس ماڈل",
    DeviceType: "آلہ کی قسم",
    Manufacture: "تیار",
    PlatformVer: "پلیٹ فارم ورژن",
    VersionCode: "ورژن کوڈ",
    VersionName: "ورژن نمبر",
    UploadDocuments: "دستاویزات اپ لوڈ کریں",
    reset: "ایپ کا ڈیٹا صاف کریں",
    ExpiredDocs: "میعاد ختم شدہ دستاویزات",
    ExpiredDocsDate: "دستاویزات کی میعاد ختم ہونے کی تاریخیں",
    AcceptOrRejectOffer: "پیشکش قبول کریں یا مسترد کریں",
    NowYouCan: "اب آپ کر سکتے ہیں",
    FillTheForm: "نیا اکاؤنٹ بنانے کے لیے نیچے دیے گئے فارم کو پر کریں۔",
    NewUpdates: "نئی اپ ڈیٹس",
    ChangeUserAccountToCompany: "فرد سے کمپنی میں تبدیلی",
    InvalidPhoneNumber: "غلط فون نمبر",
    RegisterDriver: "رجسٹر ڈرائیور",
    RegisterFleetCompany: "رجسٹر فلیٹ کمپنی",
    RegisterClientCompany: "رجسٹر کلائنٹ کمپنی",
    PleaseAcceptTermsAndConditions:
      "براہ کرم ٹرپ کی درخواست کرنے کے لیے شرائط و ضوابط کو قبول کریں",
    SendToDrivers: "تمام ڈرائیوروں کو بھیجیں",
    EnableLocationTitle: "مقام کی خدمت",
    EnableLocationMessage:
      "براہ کرم لوکیشن سروس (GPS) کو فعال کریں تاکہ آپ R2 ایپ استعمال کر سکیں۔",
    Loading: "لوڈ ہو رہا ہے...",
    Message: "پیغام",
    LocationErrorTitle: "مقام کی معلومات",
    LocationErrorMessage:
      "معذرت ہم آپ کے مقام کی معلومات حاصل نہیں کر سکے، براہ کرم دوبارہ کوشش کریں۔",
    EnableInternetMessage:
      "براہ کرم انٹرنیٹ سروس کو فعال کریں تاکہ آپ R2 ایپ استعمال کر سکیں۔",
    EnableInternetTitle: "انٹرنیٹ سروس",
    LoadingErrorMessage:
      "معذرت درخواست لوڈ کرتے وقت کچھ غلط ہو گیا، براہ کرم دوبارہ کوشش کریں۔",
    ServiceErrorMessage:
      "معذرت، سروس ابھی آپ کے ملک میں دستیاب نہیں ہے۔",
    LoadingErrorTitle: "لوڈنگ کی خرابی",
    RequestBiadjo: "ٹرپ کی درخواست کریں",
    SO1: "نئی",
    SO2: "پیشکش بھیجی گئی",
    SO3: "پیشکش مسترد کر دی گئی",
    SO4: "پیشکش قبول کر لی گئی",
    CS1: "نئی",
    CS2: "کام جاری ہے",
    CS3: "ہو گیا",
    AddNewState: "نئی ریاست شامل کریں۔",
    PS1: "ادائیگی",
    PS2: "بلا معاوضہ",
    PS3: "ملتوی",
    BS1: "نئی درخواست",
    BS2: "آفرز کا انتظار ہے",
    BS3: "پیشکش کا انتخاب",
    BS4: "منتخب شدہ",
    BS5: "کام جاری ہے",
    BS6: "کلائنٹ کے پاس پہنچ گیا",
    BS7: "کارگو لوڈ ہو رہا ہے",
    BS8: "منزل کے راستے میں",
    BS9: "منزل پر پہنچ گیا",
    BS10: "کارگو اتار رہا ہے",
    BS11: "ہو گیا",
    BS12: "کوئی ڈرائیور نہیں ملا",
    BS13: "منسوخ",
    BS14: "بند",
    BS15: "اطلاع شدہ حادثہ",
    BS16: "حادثے کے ساتھ بند",

    DPS1: "ادائیگی",
    DPS2: "بلا معاوضہ",
    DPS3: "پابند",

    U1: "کلو",
    U2: "ٹن",

    AS1: "فعال",
    AS2: "غیر متحرک",
    AS3: "ہولڈ پر",
    AS4: "معطل",
    AS5: "دستاویزات اپ لوڈ کرنا",
    AS6: "ڈیٹا کی تصدیق کا انتظار ہے",
    DS1: "اپ لوڈ نہیں ہوا",
    DS2: "اپ لوڈ کیا گیا",
    DS3: "قبول ہے",
    DS4: "مسترد",
    TT1: "لائٹ ڈیوٹی ٹرک",
    TT2: "میڈیم 4 میٹر ڈیوٹی ٹرک",
    TT3: "ہیوی 6 میٹر ڈیوٹی ٹرک",
    TT4: "ٹریلر ٹرک",
    TT5: "سیمی ٹریلر ٹرک",
    TT6: "فلیٹ بیڈ ٹرک",
    TT7: "لائٹ ڈیوٹی باکس ٹرک",
    TT8: "میڈیم 4 میٹر ڈیوٹی باکس ٹرک",
    TT9: "ہیوی 6 میٹر ڈیوٹی باکس ٹرک",
    TT10: "سیمی ٹریلر باکس ٹرک",
    TT11: "ہلکا ریفریجریٹڈ ٹرک",
    TT12: "میڈیم 4 میٹر ریفریجریٹڈ ٹرک",
    TT13: "بھاری 6 میٹر ریفریجریٹڈ ٹرک",
    TT14: "سیمی ٹریلر ریفریجریٹڈ ٹرک",
    TT15: "لکڑی ٹرانسپورٹ ٹرک",
    TT16: "کاریں ٹرانسپورٹ ٹرک",
    TT17: "کار ٹوونگ ٹرک",
    TT23: "سوزوکی اوپن ٹرک",
    TT24: "سوزوکی باکس ٹرک",
    AT0: "انفرادی",
    AT1: "ایڈمن",
    AT2: "ملازم",
    AT3: "کمپنی کا مالک",

    TO0: "ڈمپ ٹرک",

    AAT0: "سپر ایڈمن",
    AAT1: "انفارمیشن ٹیکنالوجی",
    AAT2: "ٹیکنیکل سپورٹ",
    AAT3: "ایڈمن",
    AAT4: "سرمایہ کار",
    AAT5: "فروخت",
    AAT6: "اکاؤنٹ مینیجر",
    AAT7: "کال سینٹر",
    AAT8: "اکاؤنٹنٹ",
    AAT9: "مینیجر لیول 1",
    AAT10: "مینیجر لیول 2",
    AAT11: "منیجر لیول 3",
    AAT12: "مینیجر لیول 4",
    AAT13: "مینیجر لیول 5",
    AAT14: "ملازم",

    P1: "پرس",
    P2: "نقد",
    P3: "کریڈٹ یا ڈیبٹ کارڈ",
    P4: "چیک",
    P5: "بینک ٹرانسفر",

    T1: "پرس سے کٹوتی",
    T2: "پرس میں کریڈٹ شامل کریں",
    T3: "بینک ٹرانسفر",
    T4: "کریڈٹ کارڈ سے کٹوتی",
    T5: "واپس کرنا",
    T6: "نقد",

    OpenBedTruck: "اوپن بیڈ ٹرک",
    ClosedBoxTruck: "بند باکس ٹرک",
    RefrigeratorTruck: "ریفریجریٹڈ ٹرک",
    SpecialTruck: "خصوصی ٹرک",
    SelectSize: "ایک سائز منتخب کریں",
    WithDumpster: "ڈمپ ٹرک",
    PickupLocation: "پک اپ لوکیشن",
    DestinationLocation: "منزل کا مقام",
    CargoTitle: "کارگو کی قسم",
    CargoDescription: "کارگو کی تفصیل",
    EstimatedValue: "کارگو کی تخمینی قیمت",
    CargoWeight: "کارگو وزن",
    Details: "تفصیلات",
    TripDetails: "سفر کی تفصیلات",
    CargoInsurance: "کارگو انشورنس",
    ResendBiadjoRequest: "دوبارہ سفر کی درخواست بھیجیں",
    LightSize: "روشنی",
    MediumSize: "درمیانی 4 میٹر",
    HeavySize: "بھاری 6 میٹر",
    TrailerSize: "ٹریلر",
    SemiSize: "سیمی ٹریلر",
    FlatbedSize: "فلیٹ بیڈ",
    WoodSize: "لکڑی",
    CarsSize: "کاریں",
    TowingSizes: "ٹاؤنگ",

    OpenTruckSized:
      "ہلکا، درمیانہ 4 میٹر، بھاری 6 میٹر، \n ٹریلر، نیم ٹریلر",
    ClosedTruckSizes: "ہلکا، درمیانہ 4 میٹر، \nبھاری 6 میٹر، نیم ٹریلر",
    RefrigeratedSizes: "ہلکا، درمیانہ 4 میٹر، \nبھاری 6 میٹر، نیم ٹریلر",
    SpecialSizes: "فلیٹ بیڈ، لکڑی، \nکاریں، کھینچنا",

    TripSchedule: "سفر کا شیڈول",
    Create: "بنانا",
    InvaliType: "غلط قسم",
    InvalidImageType:
      "غلط تصویر کی قسم براہ کرم یقینی بنائیں کہ تصویر (png-jpg-tiff) ہے",
    DriverInfo: "ڈرائیور کی معلومات",
    UserInfo: "صارف کی معلومات",
    LessThanOrEqual: "اس سے کم یا برابر",
    GreaterThanOrEqual: "اس سے بڑا یا برابر",
    BankAccountInfo: "بینک اکاؤنٹ کی معلومات",
    ApplyBankInfoToAllDrivers: "تمام ڈرائیوروں پر بینک کی معلومات کا اطلاق کریں",
    VehicleInfo: "گاڑی کی معلومات",
    FullName: "پورا نام",
    Home: "گھر",
    home: "گھر",
    Price: "قیمت",
    PaymentID: "ادائیگی کی شناخت",
    EG_Currency: "EGP",
    LY_Currency: "LYD",
    CZ_Currency: "EUR",
    US_Currency: "USD",
    GB_Currency: "GBP",
    FleetCompanies: "فلیٹ کمپنیاں",
    UserAccount: "صارف اکاؤنٹ",
    Driver: "ڈرائیور",
    Drivers: "ڈرائیور",
    User: "صارف",
    Clients: "کلائنٹس",
    BiadjoTitle: "R2 ایڈمن پورٹل",
    BiadjoNumber: "ٹرپ نمبر",
    AssignBiadjo: "سفر کے لیے تفویض کریں",
    Setting: "ترتیبات",
    Biadjo: "سفر",
    PaymentGateway: "پیسے ادا کرنے کا طریقہ",
    Scheduled: "شیڈول شدہ",
    ScheduleConflict: "شیڈول تنازعہ",
    Edit: "ترمیم",
    Back: "پیچھے",
    FirstName: "پہلا نام",
    LastName: "آخری نام",
    PhoneNumber: "فون نمبر",
    DriverBalance: "ڈرائیور بیلنس",
    AccountStatus: "اکاؤنٹ اسٹیٹس",
    VacationMode: "چھٹی کا موڈ",
    VehicleType: "گاڑی کی قسم",
    VehicleOption: "گاڑی کا اختیار",
    UserBalance: "صارف کا بیلنس",
    VATREQ: "ٹیکس بل",
    KMBiadjo: "کلومیٹر کا سفر",
    AddDriverPriceandAssignDrivertoKMBiadjo: "کلو میٹر قیمت کے ساتھ ڈرائیور کو تفویض کریں",
    DriverPrice: "ڈرائیور کی قیمت",
    trueBool: "جی ہاں",
    falseBool: "نہیں",
    BiadjoAcceptedKMPrice: "کلو میٹر قیمت کے ساتھ قبول شدہ سفر",
    KMOffer: "کلو میٹر آفر",
    showNoDataAvailable: "کوئی ڈیٹا دستیاب نہیں... براہ کرم دوبارہ کوشش کریں",
    NoNotesAvailable: "کوئی نوٹس دستیاب نہیں",
    Error: "خرابی",
    SorryYouAccountisNotActive: "معاف کیجئے گا..آپ کا اکاؤنٹ ایکٹیو نہیں ہے",
    WelcomeMsg: "واپس خوش آمدید، براہ کرم اپنے اکاؤنٹ میں لاگ ان ہوں۔",
    Update: "اپ ڈیٹ",
    showError: "کچھ غلط ہو گیا... براہ کرم دوبارہ کوشش کریں",
    showUpdate: "ریکارڈ کامیابی سے اپ ڈیٹ ہو گیا...",
    ResetPassword: "پاس ورڈ ری سیٹ",
    showCheckEmail: "براہ کرم نئے پاس ورڈ کے لیے اپنا ای میل چیک کریں...",
    showPasswordChanged: "پاس ورڈ بدل گیا...",
    ConflictedBiadjo: "متضاد سفر",
    BodyConflictedBiadjo:
      "آپ کے پاس نئے متضاد دورے ہیں، برائے مہربانی اسے جلد از جلد حل کریں",
    Dashboard: "ڈیش بورڈ",
    NoDriverFound: "ڈرائیوروں کے بغیر سفر",
    WaitingForVerficationDrivers: "تصدیق کا انتظار ہے",
    Height: "اونچائی",
    Length: "لمبائی",
    Width: "چوڑائی",
    Country: "ملک",
    CustomerId: "گاہک کی شناخت",
    StartCountry: "ملک شروع کریں",
    StartCountryCode: "ملکی کوڈ شروع کریں",
    EndCountry: "آخری ملک",
    EndCountryCode: "کنٹری کوڈ کا اختتام",
    FindNearByDrivers: "ڈرائیوروں کے قریب تلاش کریں",
    Email: "ای میل",
    NewCity: "نیو سٹی",
    Calendar: "کیلنڈر",
    Shop: "دکان",
    Cart: "کارٹ",
    WishList: "خواہش کی فہرست",
    Checkout: "اس کو دیکھو",
    UI: "یوزر انٹرفیس",
    Colors: "رنگ",
    Card: "کارڈ",
    Basic: "بنیادی",
    Table: "ٹیبل",
    Components: "اجزاء",
    Alert: "سی الرٹ",
    Breadcrumb: "روٹی رمب",
    Collapse: "گرنے",
    Progress: "ترقی",
    Upload: "اپ لوڈ کریں",
    Select: "منتخب کریں",
    Switch: "سوئچ",
    BiadjMap: "سفر کا نقشہ",
    Radio: "ریڈیو",
    Input: "ان پٹ",
    Pages: "صفحات",
    Authentication: "تصدیق",
    Login: "لاگ ان کریں",
    Register: "رجسٹر",
    ForgotPassword: "پاسورڈ بھول گے ؟",
    LockScreen: "اسکرین کو لاک کرنا",
    Miscellaneous: "متفرق",
    ComingSoon: "جلد آرہا ہے",
    404: "404",
    500: "500",
    NotAuthorized: "مجاز نہیں",
    Maintenance: "دیکھ بھال",
    Filters: "فلٹرز",
    Profile: "فلٹرز",
    Invoice: "انوائس",
    FAQ: "عمومی سوالات",
    Search: "تلاش",
    UpdateImage: "تصویر کو اپ ڈیٹ کریں",
    AssignToMe: "مجھے تفویض کرو",
    KnowledgeBase: "علم کی بنیاد",
    ChartsAndMaps: "چارٹس اور نقشے",
    Charts: "چارٹس",
    GoogleMap: "گوگل کا نقشہ",
    Extensions: "توسیعات",
    DragAndDrop: "ڈریگ اینڈ ڈراپ",
    Datepicker: "تاریخ چننے والا",
    DatetimePicker: "ڈیٹ ٹائم چننے والا",
    AccessControl: "ایکسیس کنٹرول",
    I18n: "I18n",
    Carousel: "کیروسل",
    Clipboard: "کلپ بورڈ",
    ContextMenu: "سیاق و سباق کا مینو",
    StarRatings: "اسٹار ریٹنگز",
    Autocomplete: "خودکار تکمیل",
    Tree: "درخت",
    Schedual: "شیڈول",
    ScheduledBiadjo: "شیڈولڈ ٹرپ",
    TransactionsHistory: "لین دین کی تاریخ",
    AddCompanyUser: "کمپنی کے صارف کو شامل کریں",
    CompanyProfile: "کمپنی پروفائل",
    AddUser: "صارف شامل کریں",
    ViewUsers: "صارفین دیکھیں",
    CompanyTrips: "کمپنی کے دورے",
    SwitchIndividualToCompany: "کمپنی میں سوئچ کریں",
    Import: "درآمد کریں",
    Export: "برآمد",
    ExportSelected: "منتخب برآمد کریں",
    Others: "دوسرے",
    DisabledMenu: "غیر فعال مینو",
    Support: "سپورٹ",
    Documentation: "دستاویزات",
    RaiseSupport: "حمایت بڑھاؤ",
    demoTitle: "کارڈ کا عنوان",
    Planning: "منصوبہ بندی",
    AnnualPlan: "سالانہ منصوبہ",
    Language: "زبان",
    Facility: "سہولت",
    ClosingDate: "آخری تاریخ",
    CreationDate: "بنانے کی تاریخ",
    BiadjosPerStatus: "فی سٹیٹس کے دورے",
    PaymentStatus: "ادائیگی کی حیثیت",
    DriverPaymentStatus: "ڈرائیور کی ادائیگی کی حیثیت",
    QuestionType: "سوال کی قسم",
    QuestionLevel: "سوال کی سطح",
    QuestionBank: "سوال بینک",
    Rating: "درجہ بندی",
    Cancel: "منسوخ کریں",
    CancelBiadjo: "سفر منسوخ کریں",
    RememberMe: "مجھے پہچانتے ہو",
    Actions: "اعمال",
    Status: "حالت",
    ContactType: "رابطے کی قسم",
    Customer: "صارف",
    DriverID: "ڈرائیور کی شناخت",
    BiadjoA: "ٹرپ اے",
    UserManagement: "صارفی انتظام",
    Users: "صارفین",
    YearSummary: "سال کا خلاصہ",
    CourseClass: "کورس کلاسز",
    Question: "سوالات کا انتظام",
    BiadjoB: "ٹرپ بی",
    Biadjo_A_Schedule: "ٹرپ اے شیڈول",
    Biadjo_B_Schedule: "ٹرپ بی شیڈول",
    BiadjoStatus: "سفر کی حیثیت",
    UserID: "صارف کی شناخت",
    RegistrationDate: "رجسٹریشن کی تاریخ",
    CountryCode: "ملک کا کوڈ",
    PaymentMethod: "ادائیگی کا طریقہ",
    Date: "تاریخ",

    NumberOfStops: "اسٹاپوں کی تعداد",
    CargoType: "کارگو کی قسم",
    CargoValue: "کارگو ویلیو",
    Value: "قدر",
    SaveChanges: "تبدیلیاں محفوظ کرو",
    TripDistance: "سفر کا فاصلہ",
    TripDuration: "دورے کا دورانیہ",
    StartLocation: "مقام شروع کریں",
    StartPlaceId: "شروع مقام کی شناخت",
    StartAddress: "شروع کا پتہ",
    EndAddress: "آخری پتہ",
    EndLocation: "آخری مقام",
    EndPlaceId: "اختتام مقام کی شناخت",
    ViewDriverDetails: "ڈرائیور کی تفصیلات دیکھیں",
    ViewUserDetails: "صارف کی تفصیلات دیکھیں",
    ViewSubmittedOrders: "جاری کردہ احکامات",
    AssignBiadjoToDriver: "ڈرائیور کو سفر تفویض کریں",
    ViewOffers: "آفرز دیکھیں",
    NumberOfOffers: "پیشکشوں کی تعداد",
    Offer: "پیشکش",
    Duration: "دورانیہ",
    Distance: "فاصلے",
    Close: "بند کریں",
    CloseBiadjo: "سفر بند کریں",
    ModefideDate: "تاریخ میدیا کریں",
    AssignToDriver: "ڈرائیور کو تفویض کریں",
    Assign: "تفویض کریں",
    Time_Deff: " زمان کی تفاوت",
    AssignedUserID: "تفویض کردہ صارف کی شناخت",
    AssignedUserName: "تفویض کردہ صارف کا نام",
    DriverUserName:"تفویض کردہ صارف کا نام",
    Name: "نام",
    BiadjoDetails: "سفر کی تفصیلات",
    CreditLimit: "اعتبار کی حد",
    PleaseEnterAllData: "تمام دادات درج کریں",
    AddInsurance: "تأمینی شامل کریں",
    InsurancePolicyNo: "  تأمینی پالیسی نمبر",
    SelectLocation: "مقام کا انتخاب کریں",
    InsuranceWarning:
      "انشورنس منسوخ کر کے، آپ لوڈنگ، ان لوڈنگ اور ٹرانسپورٹ کے دوران کارگو کی حفاظت کے مکمل طور پر ذمہ دار ہیں",

    FreeTrips: "آزاد سفر",
    AccountType: "اکاؤنٹ کی قسم",
    Password: "پاس ورڈ",
    Confirm: "تصدیق کریں",
    ConfirmAndPrint: " تصدیق اور پرنٹ کریں",
    BirthDate: "تاریخ میلاد",
    DriverLicense: "ڈرائیور لائسنس",
    NationalID: "آئی ٹی نال ایڈ کارڈ نمبر",
    Documents: "مستندات",
    CompletedTrips: "مکمل سفر",
    LicensePlate: "لائسنس پلیٹ",
    BankAddress: "بنک کا پتہ",
    SWIFT: "سویفٹ",
    City: "شہر",
    StartCity: "شروع شہر",
    Balance: "بیلنس",
    EndCity: "آخری شہر",
    StartState: "شروع دیگر",
    EndState: "آخری دیگر",
    Insurance: "تأمینی",
    InsuranceValue: "تأمینی ویلیو",
    AccountNumber: "اکاؤنٹ نمبر",
    BranchName: "چلگیری نام",
    BankName: "بنک نام",
    vacationMode: "اجازہ کا حالت",
    DriverNo: "ڈرائیور نمبر",
    VehicleMake: "مادہ کا سائٹ",
    VehicleModel: "مادہ موڈ",
    VehicleColor: "مادہ کا رنگ",
    EditUser: "صارف کی ترمیم کریں",
    ClientInformation: "مشتری کی معلومات",
    SubmittedOrderNumber: "جاری کردہ احکام نمبر",
    Save: "محفوظ کریں",
    ConfirmChange: "تصدیق کریں",
    YouAreAboutToChangeThis: "آپ اس تبدیل کرنا چاہتے ہیں",
    YouAreAboutToSendOrder: "آپ اس سفر کا ارسال کرنا چاہتے ہیں",

    YouAreAboutToChangeDriverStatus:
      "آپ اس ڈرائیور کی حالت تبدیل کرنا چاہتے ہیں",
    YouAreAboutToChangeDriverData: "آپ اس ڈرائیور کی تفصیلات تبدیل کرنا چاہتے ہیں",
    YouAreAboutToAssignDriver:
      "آپ اس ڈرائیور کو تفویض کرنا چاہتے ہیں",
    Change: "تبدیل کریں",
    YouAreAboutToResetPassswordByEmail:
      "آپ اس ای میل کے ساتھ پاس ورڈ ری سفر کے ساتھ تبدیل کرنا چاہتے ہیں",
    ConflictedBiadjos: "تعارضی سفر",
    YouAreAboutToResetPasssword: "آپ اس پاس ورڈ ری سفر کے ساتھ تبدیل کرنا چاہتے ہیں",
    YouAreAboutToUpdateBankInfo: "آپ اس بنک کے تفصیلات تبدیل کرنا چاہتے ہیں",
    AddBalance: "بیلنس اضافی کریں",
    Reports: "گزارشات",
    SubmitOffer: "پیش اتے کریں",
    OfferWillSendWithValue: "پیش اتے کے ساتھ ویلیو کے ساتھ ارسال ہو جائے گا",
    RegisterCreatedSuccessfuly: "رجیستریش کامیابی سے تخریب کر دیا گیا",
    AlreadyExist: "پہلے ہی موجود ہے",
    ThisUserAlreadyExist: "یہ صارف پہلے ہی موجود ہے",
    BiadjosHistory: " سفر کی تاریخ",
    ScheduledBiadjos: "مشروعی سفر",
    BiadjoHistory: "سفر کی تاریخ",
    UnpaidBiadjos: "نقد نہیں کردہ سفر",
    ClosingDateFrom: "تاریخ کی ختم کرنے کی درخواست",
    ClosingDateTo: "تاریخ کی ختم کرنے کی درخواست",
    DeductBalance: "بیلنس کی تصرف",
    PrintInvoice: "فاکتور کا پرنٹ کریں",
    printpayment: "پرنٹ کریں",
    UnclosedBiadjos: "ختم نہیں کردہ سفر",
    BiadjosNeedInsurance: "تعارضی سفر",
    OffersWaitingForApproval: "پیش اتے کی توصیف",
    UnStartedBiadjos: "شروع نہیں ہوئے سفر",
    Reset: "ری سفر",
    ClosedBiadjos: "ختم ہوئے سفر",
    DriverNotFound: "ڈرائیور نہیں ملا",
    ClientNotFound: "مشتری نہیں ملا",
    UserNotFound: "صارف نہیں ملا",
    PaymentDetails: "پینٹ کی تفصیلات",
    TransactionsHistoryOfClient: "مشتری کی تراکنش کا تاریخ",
    ViewTransactionsHistory: "تراکنش کا تاریخ دیکھیں",
    UnPaidDriverList: "نقد نہیں کردہ ڈرائیور کا فہرست",
    RecieptNumber: "پیش اتے کی شمار",
    TransactionType: "تراکنش کی قسم",
    CommercialRegistrationID: "تجارتی رجسٹریشن کی شناخت",
    TransactionNumber: "تراکنش کا شمار",
    UnPaidBiadjosCount: "نقد نہیں کردہ سفر کی تعداد",
    YouAreAboutToPay: "آپ اس پینٹ کے ساتھ درکار ہو جائے گا",
    PaiedBiadjosTotal: "پینٹ کی قیمت",
    PaiedBiadjosCount: "پینٹ کی تعداد",
    PaiedBiadjosList: "پینٹ کی فہرست",
    UnPaiedBiadjosTotal: "نقد نہیں کردہ سفر کی قیمت",
    UnPaiedBiadjosCount: "نقد نہیں کردہ سفر کی تعداد",
    UnPaiedBiadjosList: "نقد نہیں کردہ سفر کی فہرست",
    PaiedIncomeTax: "پینٹ کی دستیابی عوارض",
    FollowDriver: "ڈرائیور کے دوران تھا",
    TotalPrice: "کل قیمت",
    Total: "کل",
    DriverTotalPayment: "ڈرائیور کی قیمت",
    SelectedBiadjoNo: "منتخب کردہ سفر کی شمار",
    FleetCompaniesList: "فلت کمپنی کی فہرست",
    CompanyName: "کمپنی کا نام",
    ZibCode: "زیب کوڈ",
    CompanyDrivers: "کمپنی کی ڈرائیور",
    CompanyDetails: "کمپنی کی تفصیلات",
    ShowCompanyDetails: "کمپنی کی تفصیلات دکھائیں",
    CompanyUsers: "کمپنی کی صارفین",
    CompanyUser: "کمپنی کا صارف",
    TaxID: "ضرائب کا شناخت",
    Address: "پتہ",
    ZipCode: "پیٹ آئٹ",
    somethingwentwrong: "!کچھ خرابی ہو گیا",
    DriverViewPrice: "ڈرائیور کو سفر کی قیمت دیکھنے کی اجازت دیں۔",
    DriverViewRequests: "ڈرائیور کو ٹرپ کی درخواستیں دیکھنے کی اجازت دیں",
    DriverSendOffer: "ڈرائیور کو پیشکشیں بھیجنے کی اجازت دیں",
    DriverViewBalance: "ڈرائیور کو بیلنس دیکھنے کی اجازت دیں",
    DriverViewTransactionHistory: "ڈرائیور کو لین دین کی تاریخ دیکھنے کی اجازت دیں",
    CompanyPermissions: "کمپنی کی اجازتیں",
    YouAreAboutToUpdateBankInfoForAllDrivers:
      "آپ تمام ڈرائیوروں کے لیے بینک کی معلومات کو اپ ڈیٹ کرنے والے ہیں",
    YouAreAboutToChangeThisUserAccountType:
      "آپ اس صارف اکاؤنٹ کی قسم کو تبدیل کرنے والے ہیں",
    ViewDrivers: "ڈرائیوروں کو دیکھیں",
    AddDriver: "ڈرائیور کا اضافہ کریں",
    ALL: "تمام",
    CompanyWalletBalance: "کمپنی کی والی بیلنس",
    RemoveDriver: "ڈرائیور کو ختم کریں",
    Remove: "ختم کریں",
    FAT1: "ایڈمن",
    FAT2: "ملازم",
    Id: "آئی ڈی",
    CompanyInformation: "کمپنی کی معلومات",
    State: "پادھلی",
    CompanyInfo: "کمپنی کی معلومات",
    CompanyID: "کمپنی کا آئی ڈی",
    WorkingField:"Working Field",
    PersonalPicture: "ذاتی تصویر",
    PersonalIDFront: "ذاتی آئی ڈی بغلتہ",
    PersonalIDBack: "ذاتی آئی ڈی واپس",
    Passport: "پاسپورٹ",
    DriverLicenseFront: "ڈرائیور لائسنس بغلتہ",
    DriverLicenseback: "ڈرائیور لائسنس واپس",
    CarRegistration: "کار کی تصدیق",
    CarRegistrationBack: "کار کی تصدیق واپس",
    LicensePlateBack: "لائسنس پلٹ بغلتہ",
    LicensePlateFront: "لائسنس پلٹ واپس",
    CarPicture: "کار کی تصویر",
    CarInsuranceDoc: "کار کی بیلنس کا تصویر",
    CargoInsuranceDoc: "کارگی کی بیلنس کا تصویر",
    BackgroundCheck: "پس منظر کی تصویر",
    DrugTest: "ڈرگ ٹیسٹ",

    PersonalPictureStatus: "ذاتی تصویر کی حیثیت",
    PersonalIDFrontStatus: "ذاتی شناختی سامنے کی حیثیت",
    PersonalIDBackStatus: "ذاتی ID کی واپسی کی حیثیت",
    PassportStatus: "پاسپورٹ کی حیثیت",
    DriverLicenseFrontStatus: "ڈرائیور لائسنس کے سامنے کی حیثیت",
    DriverLicensebackStatus: "ڈرائیور لائسنس کی واپسی کی حیثیت",
    CarRegistrationStatus: "کار رجسٹریشن کی حیثیت",
    CarRegistrationBackStatus: "کار کی رجسٹریشن کی واپسی کی حیثیت",
    LicensePlateBackStatus: "لائسنس پلیٹ بیک اسٹیٹس",
    LicensePlateFrontStatus: "لائسنس پلیٹ کے سامنے کی حیثیت",
    CarPictureStatus: "کار کی تصویر کی حیثیت",
    CarInsuranceDocStatus: "کار انشورنس ڈاکٹر کی حیثیت",
    CargoInsuranceDocStatus: "کارگو انشورنس ڈاکٹر کی حیثیت",
    BackgroundCheckStatus: "بیک گراؤنڈ چیک اسٹیٹس",
    DrugTestStatus: "ڈرگ ٹیسٹ سٹیٹس",
    YouAreAboutToChangeDocumentStatus:
      "آپ دستاویز کی حیثیت تبدیل کرنے والے ہیں",

    FollowOnMap: "نقشے پر عمل کریں۔",
    Amount: "قیمت",
    TransactionNo: "  تراکنش کا نمبر",
    ReceiptNumber: "رسید کا نمبر",
    TransactionReferenceForCreditDebitCard:
      "کریڈٹ/ڈیبٹ کارڈ کے لین دین کا حوالہ",
    ChequeNumber: "چیک کا نمبر",
    BankTransferReference: "بنٹ کا ترنز پر دین کا حوالہ",
    TransactionInfo: "تراکنش کی معلومات",
    NewBalance: "نیا بیلنس",
    recieptNo: "رسید کا نمبر",
    AccidentReport: "آئیکن رپورٹ",
    ClientId: "کلاینٹ کا آئی ڈی",
    AdminUserId: "ایڈمن یوزر کا آئی ڈی",
    CloseAccidentBiadjo: "آئیکن بیڈجو",
    CloseAccidentBiadjoNote:
      "براہ کرم یقینی بنائیں کہ حادثے کی تصاویر اور دستاویزات اپ لوڈ کر دی گئی ہیں",
    YouAreAboutToCloseBiadjo: "آپ ٹرپ بند کرنے والے ہیں",
    ReportAccident: "حادثے کی اطلاع دیں",
    ConfirmClose: "بند کی تصدیق کریں",
    Submit: "جمع کرائیں",
    AccidentPictures: "حادثے کی تصاویر",
    AddNote: "نوٹ شامل",
    ConfirmRemove: "ہٹانے کی تصدیق کریں",
    YouAreAboutToRemoveDriver: "ہٹانے کی تصدیق کریں",
    Agree: "اتفاق",
    StatusLogHistory: "اسٹیٹس لاگ ہسٹری",
    DateTime: "تاریخ وقت",
    LogNumber: "لاگ نمبر",
    SuccessAddNote: "نوٹ کامیابی کے ساتھ شامل کیا گیا",

    AvailableSizes: "دستیاب سائز",
    Category: "قسم",
    Preview: "پیش نظارہ",

    YouAreAboutToChangeThisClientData:
      "آپ اس کلائنٹ کے ڈیٹا کو تبدیل کرنے والے ہیں",
    YouAreAboutToChangeThisClientStatus:
      "آپ اس کلائنٹ کی حیثیت کو تبدیل کرنے والے ہیں",
    VAT: "وی اے ٹی",
    SessionExpired: "سیشن ختم ہو گیا، براہ کرم دوبارہ لاگ ان کریں...",
    Logout: "لاگ آوٹ",
    YourCancelationFeesIs: "آپ کی منسوخی کی فیس ہے",
    THereIsNoCancelationFees: "کوئی منسوخی فیس نہیں ہے",
    InvalidEmailPleasetryagain: "غلط ای میل... براہ کرم دوبارہ کوشش کریں..!",
    InvalidPasswordPleasetryagain: "غلط پاس ورڈ... براہ کرم دوبارہ کوشش کریں..!",
    Deletedrecord: "حذف شدہ ریکارڈ",
    Theselectedrecordwassuccessfullydeleted:
      "منتخب ریکارڈ کامیابی سے حذف کر دیا گیا",
    Areyousureyouwantdeletethisrecord:
      "کیا آپ واقعی اس ریکارڈ کو حذف کرنا چاہتے ہیں؟",
    LoginAttempt: "لاگ ان کی کوشش",
    Youarealreadyloggedin: "آپ پہلے ہی لاگ ان ہیں!",
    BiadjoSchedualeForWeek: "ہفتہ وار طے شدہ سفر",
    IncomePerDayforWeek: "ہفتہ وار آمدنی فی دن",
    DriversPerTruckType: "ڈرائیور فی ٹرک کی قسم",

    TrucksTypes: "ٹرک کی قسم",
    BiadjoSchedualePerStatusForToday: "آج کا سفر طے کریں",
    DriversPerStatus: "ڈرائیور فی سٹیٹس",
    ClosedBiadojsPerDayForWeek: "ہفتے کے لیے فی دن بند دورے",
    UnClosedBiadojsPerDayForWeek: "ہفتے کے لیے فی دن غیر بند دورے",
    BiadojsPerTruckTypeForWeek: "ہفتے کے لیے فی ٹرک کی قسم کے دورے",
    BiadojsPerDayForWeek: "ہفتے کے لیے فی دن سفر",
    TodayBiadojsForTodayperStatus: "آج کے دورے فی سٹیٹس",
    ToDayBiadojsPerTrucktype: "آج کے دورے فی ٹرک کی قسم",
    WatingForVerificationDriversCount: "تصدیق ڈرائیوروں کی گنتی کا انتظار ہے",
    NONotifications: "آپ کی اطلاع کی فہرست خالی ہے",
    UnStartedBiadjosCount: "غیر شروع شدہ دوروں کی گنتی",
    UnInsuredBiadjosCount: "غیر بیمہ شدہ دوروں کا شمار",
    NoDriverFoundBiadojsCount: "گاڑی کے مالک کا نام",
    VehicleOwnerFullName: "گاڑی کے مالک کا نام",
    personalPicture: "ّذاتي نصوير",
    personalIDFront: "پرسنل آئی ڈی فرنٹ",
    personalIDBack: "ذاتی ID واپس",
    passport: "پاسپورٹ",
    driverLicenseFront: "ڈرائیور لائسنس فرنٹ",
    driverLicenseback: "ڈرائیور لائسنس واپس",
    carRegistration: "کار رجسٹریشن",
    carRegistrationBack: "کار کی رجسٹریشن واپس",
    licensePlateBack: "لائسنس پلیٹ واپس",
    licensePlateFront: "لائسنس پلیٹ فرنٹ",
    carPicture: "کار کی تصویر",
    carInsuranceDoc: "کار انشورنس ڈاکٹر",
    cargoInsuranceDoc: "کارگو انشورنس دستاویز",
    backgroundCheck: "بیک گراؤنڈ چیک",
    drugTest: "ڈرگ ٹیسٹ",
    taxIDCard: "گاڑی کے مالک کے لیے ٹیکس شناختی کارڈ",
    YourSearchingCountry: "آپ کی تلاش کا ملک ہے",
    ClientCompanyId: "کلائنٹ کمپنی i d",
    FleetCompanyId: "فلیٹ کمپنی I D",
    Arabic: "عربی",
    English: "انگریزی",
    Urdu: "اردو",
    GenerateReport: "رپورٹ بنائیں",
    UnPaiedDriversDetails: "بغیر معاوضہ ڈرائیوروں کی تفصیلات",
    UnPaiedCompaniesDetails: "غیر ادا شدہ کمپنیوں کی تفصیلات",
    Income: "آمدنی",
    IncomeDetailed: "تفصیل میں آمدنی",
    IncomeReportSummary: "انکم سمری رپورٹ",
    UnOfficialIncomeSummaryReport: "غیر سرکاری آمدنی خلاصہ رپورٹ",
    AddCity: "شہر شامل کریں",
    AddCountry: "ملک شامل کریں",
    AddPhone: "فون شامل کریں",
    AddState: "ریاست شامل کریں",
    AddZibCode: "زیب کوڈ شامل کریں",
    CommercialRegistrationNo: "کمرشل رجسٹریشن نمبر",
    Currancy: "کرنسی",
    CurrancyISOCode: "کرنسی آئی ایس او کوڈ",
    DriverTAXPercentage: "ڈرائیور ٹیکس فیصد",
    InsurancePercentage: "انشورنس کا فیصد",
    IntAddCity: "انٹ ایڈ سٹی",
    IntAddCountry: "انٹ ایڈ کنٹری",
    IntAddPhone: "انٹ فون شامل کریں",
    IntAddState: "انٹ ایڈ اسٹیٹ",
    IntAddZibCode: "انٹ ایڈ زیب کوڈ",
    IntAddress: "انٹ ایڈریس",
    VATID: "VAT ID",
    VATPercentage: "VAT فیصد",
    DriverPercentage: "ڈرائیور کا فیصد",
    SupportPhoneNo: "سپورٹ فون نمبر",
    SupportEmail: "سپورٹ ای میل",
    UserInitialBalance: "صارف کا ابتدائی بیلنس",
    UserInitialFreeTrips: "صارف کے ابتدائی مفت دورے",
    MaxUserCredit: "زیادہ سے زیادہ صارف کریڈٹ",
    DriverInitialFreeTrips: "ڈرائیور کے ابتدائی مفت دورے",
    DriverInitialBalance: "ڈرائیور کا ابتدائی بیلنس",
    DriverInitialRating: "ڈرائیور کی ابتدائی درجہ بندی",
    MaxDriverCredit: "زیادہ سے زیادہ ڈرائیور کریڈٹ",
    CancelationFeePercentage: "منسوخی فیس کا فیصد",
    IncorrectCode: "غلط کوڈ",
    PleaseFillAllRequiredData: "براہ کرم تمام مطلوبہ ڈیٹا پُر کریں...",
    ChangeType: "تبدیل کی قسم",
    YouAreAlreadyloggedin: "آپ پہلے ہی لاگ ان ہیں!",
    Deactivate: "غیر فعال کریں",
    EditCompanyBankAccountInformation: "کمپنی کے بینک اکاؤنٹ کی معلومات میں ترمیم کریں",
    CompanyTransactionHistory: "کمپنی کی لین دین کی تاریخ",
    CompanyWallet: "کمپنی والیٹ",
    FollowBiadjoOnMap: "نقشے پر سفر کی پیروی کریں",
    CompanySubmittedOrder: "کمپنی کے بھیجے گئے آرڈرز",
    DriverSubmittedOrders: "ڈرائیور نے آرڈر جمع کرائے",
    EditCompanyUser: "کمپنی کے صارف میں ترمیم کریں",
    EditCompanyProfile: "کمپنی پروفائل میں ترمیم کریں",
    CompanyUserRegister: "کمپنی صارف رجسٹر",
    CompanyUserProfile: "کمپنی کا صارف پروفائل",
    UserPersonalDetails: "صارف کی ذاتی تفصیلات",
    DriverProfile: "ڈرائیور پروفائل",
    BankAccountEdit: "بینک اکاؤنٹ میں ترمیم کریں",
    DriverScheduledBiadjos: "ڈرائیور کے طے شدہ دورے",
    DriverBiadjosHistory: "ڈرائیور کے دوروں کی تاریخ",
    DriverLocation: "ڈرائیور کا مقام",
    TransactionsHistoryOfDriver: "ڈرائیور کی لین دین کی تاریخ",
    ContactUs: "ہم سے رابطہ کریں",
    //ContactUs: "امریکہ سے رابطہ کریں",
    ScheduledBiadjosForCompany: "شیڈول ٹرپس",
    CompanyBiadjosHistory: "دوروں کی تاریخ",
    DriverUnpaidBiadjos: "ڈرائیور کا بلا معاوضہ دورہ",
    logout: "زبردستی لاگ آؤٹ کریں",
    YouAreAboutTo: "تم ہونے والے ہو",
    IdleSessionLogout: "بیکار سیشن لاگ آؤٹ",
    BiadjoStatusLogHistory: "ٹرپ اسٹیٹس لاگ ہسٹری",
    ReportBiadjoAccident: "رپورٹ ٹرپ ایکسیڈنٹ",
    BiadjoAccidentReport: "ٹریپ ایکسیڈنٹ رپورٹ",
    DeductClientBalance: "کلائنٹ بیلنس کٹوتی کریں",
    DeductDriverBalance: "ڈرائیور بیلنس کٹوائیں",
    AddClientBalance: "کلائنٹ بیلنس شامل کریں",
    AddDriverBalance: "ڈرائیور بیلنس شامل کریں",
    UserDetails: "صارف کی تفصیلات",
    UserSearch: "صارف کی تلاش",
    FleetDriver: "فلیٹ ڈرائیور",
    BiadjoHistoryOfClient: "کلائنٹ کی ٹرپ ہسٹری",
    ScheduledBiadjoOfClient: "کلائنٹ کا طے شدہ سفر",
    ClientProfile: "کلائنٹ پروفائل",
    TextCopiedSuccessfully: "متن کامیابی سے کاپی ہو گیا",
    ClientEdit: "کلائنٹ پروفائل میں ترمیم کریں",
    DriverDocuments: "ڈرائیور کے دستاویزات",
    DriverEdit: "ڈرائیور پروفائل میں ترمیم کریں",
    BankAccountInformation: "بینک اکاؤنٹ کی معلومات",
    EditCompany: "کمپنی کی معلومات میں ترمیم کریں",
    CompanyBankAccountInformation: "کمپنی کے بینک اکاؤنٹ کی معلومات",
    FleetCompaniesDetails: "فلیٹ کمپنیوں کی تفصیلات",
    FleetCompanyWalletBalance: "فلیٹ کمپنی والیٹ بیلنس",
    CompanySubmitedOrdersAndOffers: "کمپنی نے آرڈرز اور پیشکشیں جمع کرائیں",
    CompanyScheduledBiadjos: "کمپنی کے طے شدہ دورے",
    AddCompanyDriver: "کمپنی ڈرائیور شامل کریں",
    FleetCompanyUserProfile: "فلیٹ کمپنی کا صارف پروفائل",
    FleetCompanyUsers: "فلیٹ کمپنی کا صارف پروفائل",
    BiadjosWithoutDrivers: "ڈرائیوروں کے بغیر سفر",
    ConflictedDetails: "متضاد سفر کی تفصیلات",
    ClientCompanyDetails: "کلائنٹ کمپنی کی تفصیلات",
    UpdateBiadjo: "دورے کو اپ ڈیٹ کریں",
    ScheduleDetails: "سفر کی تفصیلات طے کریں",
    BiadjoSearch: "ٹرپ سرچ",
    CountryEdit: "ملک میں ترمیم کریں",
    CreateAccount: "اکاؤنٹ بنائیں",
    ClientExpensesStatement: "کلائنٹ کے اخراجات کا بیان",
    StartDate: "شروع کرنے کی تاریخ",
    EndDate: "آخری تاریخ",
    Flag: "پرچم",
    Code: "کوڈ",
    showDriverAddeddSuccessfuly: "ڈرائیور نے کامیابی سے اضافہ کیا",
    Send: "بھیجیں",
    ClickHere: "یہاں کلک کریں",
    In: "میں",
    EG: "مصر",
    LY: "لیبیا",
    NumberOfWorkers: "کارکنوں کی مطلوبہ تعداد",
    ThereISNumber: "کی تعداد",
    CargoWorkersConfirmation: "کیا آپ کارکنوں سے سامان لوڈ کرنے کی درخواست کرنا چاہتے ہیں؟",
    Wallet: "پرس",
    DriversWaitingForVerfication: "ڈرائیور تصدیق کا انتظار کر رہے ہیں",
    EditOffer: "پیشکش میں ترمیم کریں",
    ACHEGDriversReport: "ACH EG ڈرائیوروں کی رپورٹ",
    ACHEGFleetsReportrepx: "ACH EG فلیٹس کی رپورٹ",
    SubmitOrder: "آرڈر جمع کرانے",
    SubmitOrderToDriverOrFleet: "ڈرائیور یا بیڑے کو آرڈر جمع کروائیں",
    ClientsCompanyList: "کلائنٹس کی کمپنی کی فہرست",
    clientCompanies: "کلائنٹ کمپنیاں",
    SubmitOfferToOrder: "آرڈر کے لیے پیشکش جمع کروائیں",
    RegisterCompany: "رجسٹر کمپنی",
    RegisterUser: "رجسٹر کلائنٹ",
    CompanyPhoneNo: "کمپنی کا فون نمبر",
    CargoInsuranceConfirmation: "کیا آپ کارگو انشورنس شامل کرنا چاہیں گے؟",
    Next: "اگل",
    TruckType: "ٹرک کی قسم",
    CargoInfo: "کارگو کی معلومات",
    TripSchedual: "ٹرپ شیڈول",
    SelectDestinationLocation: "منزل کا مقام منتخب کریں",
    Enteralocation: "ایک مقام درج کریں",
    TermsandConditions: "شرائط و ضوابط",
    TermsAndConditions: "شرائط و ضوابط۔",
    Accept: "قبول کرو",
    SelectPickupLocation: "پک اپ کا مقام منتخب کریں",

    Schedule: "شیڈول",
    NumberOfTrips: "اسٹاپوں کی تعداد",
    Trips: "رک جاتا ہے",
    TripNo: "مرحلہ نمبر:",
    PickupStopLocation: "پک اپ اسٹاپ لوکیشن",
    Areyoushureyouwanttoacceptthisoffer:
      "کیا آپ واقعی اس پیشکش کو قبول کرنا چاہتے ہیں؟",
    Areyoushureyouwanttoremovethisoffer:
      "کیا آپ واقعی اس پیشکش کو ہٹانا چاہتے ہیں؟",
    OffersForBiadjo: "سفر کے لیے پیشکش",
    NewBiadjoRequest: "نئی سفر کی درخواست",
    changeCountry: "ملک تبدیل کیجئے",
    ReConfirm: "دوبارہ تصدیق کریں",
    GoToMob: "(موبائل) پر جائیں",
    GoToWeb: "(ویب) پر جائیں",
    GoTo: "صفحہ پر جائیں",

    DriverWaitingForVerfication: "ڈرائیور تصدیق کا انتظار کر رہا ہے",
    BodyDriverWaitingForVerfication:
      "ایک ڈرائیور ID کے ساتھ تصدیق کا انتظار کر رہا ہے",

    NewBiadjo: "نیا سفر",
    BodyNewBiadjo: "آئی ڈی کے ساتھ ایک نیا سفر ہے",

    BiadjoWithoutDrivers: "ڈرائیور کے بغیر سفر",
    BodyBiadjoWithoutDrivers: "ڈرائیور کی شناخت کے بغیر ایک نیا سفر ہے",

    BodyOffersWaitingForApproval:
      "ایک نئی پیشکش آئی ڈی کے ساتھ منظور یا مسترد ہونے کا انتظار کر رہی ہے",

    DoneBiadjos: "ڈن ٹرپس",
    BodyDoneBiadjos:
      "ایک ٹرپ ہو چکا ہے اور کلائنٹ کا ID کے ساتھ اسے بند کرنے کا انتظار ہے",

    UnstartedBiadjos: "غیر شروع شدہ دورے",
    BodyUnstartedBiadjos: "آئی ڈی کے ساتھ غیر شروع شدہ سفر ہے",
    BankAccountInformationScreen: "بینک اکاؤنٹ کی معلومات",
    EditProfileScreen: "پروفائل میں ترمیم کریں",
    ChangeLanguageScreen: "زبان تبدیل کریں",
    ChangePhoneScreen: "فون نمبر تبدیل کریں",
    ContactUsScreen: "امریکہ سے رابطہ کریں",
    SupportScreen: "سپورٹ اسکرین",
    WalletHistoryScreen: "لین دین کی تاریخ",
    WalletSavedPaymentScreen: "محفوظ ادائیگی کے طریقے",
    Notification: "اطلاع",
    PleaseAllowNotification:
      "براہ کرم اطلاعات کو نئی اپ ڈیٹس حاصل کرنے کی اجازت دیں",
    BodyOfferAccepted: "آپ کے سفر کی پیشکش ID کے ساتھ قبول کی گئی ہے",
    RecieveBiadjoRequest: "ٹرپ کی درخواست وصول کریں",
    bodyRecieveBiadjoRequest: "آئی ڈی کے ساتھ سفر کی نئی درخواست",
    BodyNewOffer: "آپ کو ٹرپ نمبر کے لیے نئی پیشکش موصول ہوئی ہے",
    YouAreAboutToChangeUserStatus: "آپ صارف کی حیثیت تبدیل کرنے والے ہیں",
    NewOffer: "نئی پیشکش",
    ClickRefresh: "ریفریش کرنے کے لیے یہاں کلک کریں یا ALT+L دبائیں",
    ViewAllNotifications: "تمام اطلاعات دیکھیں",
    AddDiscount: "رعایت شامل کریں",
    ResetID: "ری سیٹ آئی ڈی",
    YouAreAboutToPayThisBiadjo: "آپ اس سفر کی ادائیگی کرنے والے ہیں",
    YouAreAboutToChangeBiadjoData: "آپ ٹرپ ڈیٹا تبدیل کرنے والے ہیں",
    deleteAllNotification: "تمام حذف کریں",
    MarkAsReadAll: "سب کو پڑھا ہوا شمار کریں ",
    AddRoadAndScaleCharges: "سڑک اور اسکیل چارجز شامل کریں",
    RoadAndScaleCharges: "سڑک اور پیمانے کے چارجز",
    DuplicateBiadjo: "ڈپلیکیٹ ٹرپ",
    LastSeen: "آخری بار دیکھا",
    OnlineDriversForTheLastWeek: "گزشتہ ہفتے کے لیے آن لائن ڈرائیورز",
    OnlineDriversPerTruckType: "آن لائن ڈرائیورز فی ٹرک کی قسم",
    AddFixedPriceOffer: "مقررہ قیمت شامل کریں",
    ShowOffersWithNoRestrict: "بغیر کسی پابندی کے آفرز دکھائیں",
    GeneralSetting: "عمومی ترتیبات",
    WithdrawalAndPayment: "واپسی اور ادائیگی",
    EditCountry: "ملک میں ترمیم کریں۔",
    AreYouSureYouWantToApproveRequest: "کیا آپ کو یقین ہے کہ آپ درخواست کو منظور کرنا چاہتے ہیں؟"

  },
  ar: {
    Descraption:"تفاصيل",
    cantaccept:"لا يمكن قبول الطلب لان رصيد المحفظة المتاحة اقل من المطلوب",
    CancelRequest:"الغاء الطلب",
    SuccessCancelRequest:"تم الغاء الطلب بنجاح",
    DriverWallet:"محفظة السائق",
    ClientWallet:"محفظة العميل",
    ClientMoneyTransferRequests:"طلبات سحب الرصيد",
    CanceledTrips:"الرحلات الملغية",
    UserDeletedRequests:"طلبات حذف المستخدمين",
    DriverBalanceRequest:"طلبات شحن السائقين",
    Deposit:"عربون مقدم",
    Transfare:"تحويل",
    DriverMoneyTransferRequests:"طلبات تحويل أموال السائق",
    ApproveRequest: "تأكيد الطلب",
    AreYouSureYouWantToApproveRequest: "هل أنت متأكد أنك تريد الموافقة على الطلب ؟",
    SuccessfullyApproved: "تم الموالفقه على الطلب بنجاح",
    IsRecivedCach:"استلام نقدي",
    IsRecivedWithPaymentGetaway:"يتم الاستلام مع بوابة الدفع",
    PaymentGetWay:"طريقة التحويل",
    UserBalanceRequest:"طلبات تغذية ارصدة العملاء",
    RequestDate:"تاريخ الطلب",
    DateApproved:"تاريخ الموافقة على الطلب",
    Item: "عنصر",
    EditCountry: "تعديل الدوله",
    AddCity: "اضافة مدينه",
    OperationStatus: "الحالة",
    OperationSide: "جهة العملية",
    OperationNumber: "رقم العملية",
    PaymentWayName: "الطريقة",
    CustomizeCustomers: "فيما يخص العملاء",
    WithdrawalPhone: "رقم الهاتف الخاص بالسحب",
    PhoneForDeposit: "رقم الهاتف الخاص بالأيداع",
    CustomizeDrivers: "فيما يخص السائقين",
    CustomizeFleets: "فيما يخص اصحاب الاسطول",
    CustomizeRemittances: "تخصيص الحوالات",
    CurrencyName: "العملة",
    CurrencyCode: "الرمز",
    ExchangeRate: "سعر الصرف",
    CurrencyGuideSetting: "اعدادات المعاملات الماليه",
    CurrencyGuide: "دليل العملات",
    Cars: "عرض السيارات المتاحة ",
    Type: "نوع",
    VehiclesAxles: "محاور السيارة",
    AddNew: "اضف جديد",
    ShapeName: "اسم الشكل",
    VehiclesShape: "شكل السيارة",
    VehiclesSetting: "إعدادت السيارات",
    VehiclesCategory: "فئة السيارات",
    VehiclesType: "انواع السيارات",
    GeneralSetting: "الأعدادات العامة",
    StateNameAr: "اسم الولاية باللغة العربية",
    StateNameEn: "اسم الولاية باللغة الانجليزية",
    CityNameAr: "اسم المدينة باللغة العربية",
    CityNameEn: "اسم المدينة باللغة الانجليزية",
    ShowOffersWithNoRestrict: "اظهار العروض بدون قيود",
    OnlineDriversForTheLastWeek: "السائقون الأونلاين أخر أسبوع",
    OnlineDriversPerTruckType: "السائقون الأونلاين حسب نوع المركبة",
    AddFixedPriceOffer: "أضافة سعر ثابت",
    LastSeen: "اخر ظهور",
    Online: "أونلاين",
    PersonalIDExp: "تاريخ انتهاء البطاقة الشخصية",
    DriverLicenseExp: "تاريخ انتهاء رخصة السائق",
    CarRegistrationExp: "تاريخ انتهاء رخصة السيارة",
    ExpiredDocs: "مستندات منتهية",
    ExpiredDocsDate: "تاريخ انتهاء المستندات",
    DocumentName: "أسم المستند",
    UploadDocuments: "تحميل المستندات",
    DuplicateBiadjo: "كرر رحلة النقل",
    AddRoadAndScaleCharges: "اضافة الكارتة و الموازين",
    RoadAndScaleCharges: "الكارتة والموازين",
    DeviceInfo: "معلومات الجهاز",
    DeviceModel: "طراز الجهاز",
    DeviceType: " نوع الجهاز",
    Manufacture: "صناعة",
    PlatformVer: "إصدار النظام الأساسي",
    VersionCode: "كود الإصدار",
    VersionName: "رقم الإصدار",
    MarkAsReadAll: "جعل الكل مقروء",
    deleteAllNotification: "حذف الكل ",
    YouAreAboutToChangeBiadjoData: "انت علي وشك تغير بيانات رحلة النقل",
    YouAreAboutToPayThisBiadjo: "انت علي وشك دفع رحلة النقل ",
    ResetID: "رقم الفاتورة",
    pay: "دفع",
    Discount: "خصم",
    AddDiscount: " أضف الخصم",
    ViewAllNotifications: "عرض جميع الإشعارات",
    Refresh: "تحديث",
    ClickRefresh: "أضغط هنا لتحديث الصفحة او أضغط Alt+L",
    NewOffer: "عرض جديد",
    BodyNewOffer: ":لقد تلقيت عرض مالي جديد لرحلة النقل رقم",
    YouAreAboutToChangeUserStatus: "انت علي وشك تغير حالة الحساب ",
    BodyOfferAccepted: "تم قبول عرضك المالي لرحلة النقل رقم",
    bodyRecieveBiadjoRequest: "يوجد طلب رحلة النقل جديد برقم ",
    RecieveBiadjoRequest: "تلقي طلب رحلة النقل",
    Notification: "الإشعارات",
    PleaseAllowNotification:
      "يرجى السماح للإشعارات لأستلام التحديثات الجديدة, ",
    BankAccountInformationScreen: "معلومات الحساب البنكي",
    EditProfileScreen: "تعديل الملف الشخصي",
    ChangeLanguageScreen: "تغيير اللغة",
    ChangePhoneScreen: "تغيير رقم الهاتف",
    ContactUsScreen: "راسلنا",
    SupportScreen: "الدعم",
    WalletHistoryScreen: "سجل المعاملات الماليه",
    WalletSavedPaymentScreen: "وسائل الدفع المحفوظه",
    New: "جديد",
    DriverWaitingForVerfication: "سائق ينتظر التحقق",
    BodyDriverWaitingForVerfication: "هناك سائق ينتظر التحقق برقم",
    Details: "تفاصيل",
    NewBiadjo: "رحلة النقل جديدة",
    BodyNewBiadjo: "هناك رحلة نقل جديد برقم",

    BiadjoWithoutDrivers: " رحلة نقل بدون سائق",
    BodyBiadjoWithoutDrivers: "هناك رحلة نقل بدون سائق برقم",

    BodyOffersWaitingForApproval:
      "هناك عروض مقدمةالانتظار الموافقة او الرفض لرحلة النقل رقم",

    DoneBiadjos: "رحلة النقل انتهى وينتظر العميل لإغلاقه",
    BodyDoneBiadjos: "هناك رحلة نقل انتهى وينتظر العميل لإغلاقه برقم",

    UnstartedBiadjos: "رحلة النقل لم يبدأ",
    BodyUnstartedBiadjos: "هناك رحلة النقل لم يبدأ برقم",
    Client: "عميل",
    GoTo: "الأنتقال إلي صفحة",
    GoToMob: "انتقل إلى (التطبيق)",
    GoToWeb: "انتقل إلى (الويب)",
    ReConfirm: "إعادة التأكيد",
    changeCountry: "تغيير الدولة",
    reset: "مسح بيانات التطبيق",
    logout: "فرض الخروج",
    YouAreAboutTo: "أنت على وشك",
    OffersForBiadjo: "عروض رحلة النقل",
    Areyoushureyouwanttoremovethisoffer:
      "هل أنت متأكد أنك تريد إزالة هذا العرض؟",
    Areyoushureyouwanttoacceptthisoffer:
      "هل أنت متأكد أنك تريد قبول هذا العرض؟",
    PickupStopLocation: "موقع المحطة",
    Minute: "دقيقة",
    KM: "كم",
    Reject: "رفض",
    Hour: "ساعة",
    Enteralocation: "ادخل الموقع ",
    Proceed: "التالى",
    TermsAndConditions: "الشروط و الأحكام.",
    TermsandConditions: "الشروط والاحكام",
    Accept: "أوافق على",
    SelectPickupLocation: "أختر نقطة الانطلاق",
    OffersWaitingForApproval: " عروض في انتظار الموافقة",
    CargoInsuranceConfirmation: "هل ترغب في إضافة تأمين على الحمولة؟",
    Next: "استمرار",
    TruckType: "نوع الشاحنة",
    CargoInfo: "معلومات الحمولة ",
    TripSchedual: "موعد انطلاق الرحلة",
    SelectDestinationLocation: "أختر نقطة الوصول",
    NumberOfOffers: "عدد العروض",
    Schedule: "موعد الرحلة",

    CompanyPhoneNo: "رقم تليفون الشركة",
    NowYouCan: "الأن يمكنك",
    FillTheForm: "املأ النموذج أدناه لإنشاء حساب جديد",
    NewUpdates: "التحديثات الجديدة",
    ChangeUserAccountToCompany: "تغيير من عميل إلي شركة",
    InvalidPhoneNumber: "رقم الهاتف غير صحيح",
    RegisterDriver: "تسجيل سائق",
    RegisterUser: "تسجيل عميل",
    RegisterFleetCompany: "تسجيل شركة أسطول",
    RegisterClientCompany: "تسجيل شركة عميل",
    SubmitOfferToOrder: " أرسل العرض إلى الطلب",
    clientCompanies: " عملاء الشركة",
    ClientsCompanyList: " قائمة عملاء الشركة",
    SendToDrivers: "الأرسال لجميع السائقين",
    EnableLocationTitle: "خدمة تحديد الموقع",
    PleaseAcceptTermsAndConditions: "  يرجى قبول الشروط والأحكام",
    EnableLocationMessage:
      "الرجاء تفعيل خدمة تحديد الموقع (GPS) لتتمكن من إستخدام التطبيق.",
    allow_permissions_title: "الأذونات",
    allow_permissions_message:
      "الرجاء قبول جميع الأذونات حتى تتمكن من استخدام تطبيق رحلة النقل.",
    Loading: "جار التحميل...",
    Message: "رسالة",
    LocationErrorTitle: "معلومات الموقع",
    LocationErrorMessage:
      "نأسف، لم نتمكن من تحديد موقعك عليه لن تتمكن من إستخدام التطبيق، الرجاء إعادة المحاولة مرة أخرى.",
    EnableInternetMessage:
      "الرجاء تفعيل خدمة الإنترنت لتتكمن من إستخدام تطبيق رحلة النقل.",
    EnableInternetTitle: "خدمة الإنترنت",
    LoadingErrorMessage:
      "نأسف، حدث خطاء أثناء تحميل التطبيق، الرجاء إعادة المحاولة مرة أخرى.",
    ServiceErrorMessage: "عفواً ، الخدمة غير متاحة في بلدك في الوقت الحالي.",
    LoadingErrorTitle: "خطاء في التحميل",
    RequestBiadjo: "طلب رحلة النقل",
    CompanyClientsList: " قائمة عملاء الشركة",
    SubmitOrderToDriverOrFleet: " إرسال الطلب إلى السائق أو الأسطول",
    SubmitOrder: " أكد الطلب",
    ACHEGDriversReport: "ACH EG تقرير السائقين",
    ACHEGFleetsReportrepx: "ACH EG تقرير الاسطول",
    EditOffer: "تعديل العرض المالى",
    ThereISNumber: "يوجدد عدد ",
    CargoWorkersConfirmation: " هل ترغب بطلب عمال تحميل بضاعة؟",
    NewBiadjoRequest: "طلب رحلة النقل جديد",

    NumberOfWorkers: "مطلوب عدد عمال للتحميل",
    DriversWaitingForVerfication: "سائقين في الانتظار التحقق من البيانات",
    ClickHere: "أضغط هنا",
    EG: "مصر",
    LY: "ليبيا",
    In: "في",
    Send: "ارسال",
    CreateAccount: "انشاء حساب",
    showDriverAddeddSuccessfuly: "تم اضافة السائق بنجاح",
    Code: "الرمز",
    Flag: "العلم",
    EndDate: "تاريخ الانتهاء",
    StartDate: "تاريخ البدء",
    FleetCompanyUsers: "مستخدمي شركة الاسطول",
    ClientExpensesStatement: " بيان مصاريف العميل",
    CountryEdit: "تعديل الدولة",
    Wallet: "المحفطة",
    "Contact-Us": "أتصل بنا",
    BiadjoSearch: "بحث رحلة النقل",
    ScheduleDetails: " تفاصيل رحلة النقل المجدولة",
    UpdateBiadjo: "تحديث رحلة النقل",
    ConflictedDetails: " تفاصيل رحلة النقل المتعارضة",
    ClientCompanyDetails: " تفاصيل شركة العميل",
    BiadjosWithoutDrivers: " رحلة النقل بدون سائق",
    FleetCompanyUserProfile: "ملف مستخدم شركة الأسطول",
    AddCompanyDriver: "اضافة سائق للشركة",
    AddDriver: "إضافة سائق",
    ViewDrivers: "عرض السائقين",
    CompanyBiadjosHistory: "تاريخ شركات رحلة النقل",
    CompanyScheduledBiadjos: " شركات رحلة النقل المجدولة",
    CompanySubmitedOrdersAndOffers: "الطلبات والعروض المقدمة من الشركة",
    FleetCompanyWalletBalance: "رصيد محفظة شركة الأسطول",
    FleetCompaniesDetails: "تفاصيل شركات الأسطول",
    CompanyBankAccountInformation: "معلومات الحساب المصرفي للشركة",
    EditCompany: "تعديل بيانات الشركة",
    BankAccountInformation: "معلومات الحساب المصرفي",
    DriverEdit: " تعديل ملف السائق",
    DriverDocuments: "ملفات السائق",
    ClientEdit: "تعديل ملف العميل",
    ClientProfile: "ملف العميل",
    TextCopiedSuccessfully: "تم النسخ بنجاح",
    ScheduledBiadjoOfClient: "رحلة النقل المجدولة للعميل",
    BiadjoHistoryOfClient: "تاريخ رحلة النقل للعميل",
    UserSearch: "بحث المستخدم",
    FleetDriver: "سائق لأسطول",
    UserDetails: "ملف المستخدم",
    UserManagement: "إدارةالمستخدم",
    AddDriverBalance: "إضافة رصيد السائق",
    AddClientBalance: "إضافة رصيد العميل",
    DeductDriverBalance: "خصم رصيد السائق",
    DeductClientBalance: "خصم رصيد العميل",
    BiadjoAccidentReport: " تقرير حادث بياجو",
    ReportBiadjoAccident: "تقرير عن حادث بياجو",
    BiadjoStatusLogHistory: "حفوظات سجل حالة رحلة النقل",
    IdleSessionLogout: "الخمول جلسة تسجيل الخروج",
    AddCity: "اضافة مدينه",
    AddCountry: "الدوله",
    AddPhone: "اضافة رقم هاتف",
    AddNewState: "اضافة محافظه",
    AddState: "الولايه / المحافظه",
    AddZibCode: "اضافة الرمز البريدي",
    CommercialRegistrationNo: "رقم السجل التجاري",
    Currancy: "العمله",
    CurrancyISOCode: "رمز ISO للعملة",
    DriverTAXPercentage: "نسبة ضريبة السائق",
    InsurancePercentage: "نسبة التأمين",
    IntAddCity: "اضافة مدينه مبدئيه",
    IntAddCountry: "اضافة دوله مبدئيه",
    IntAddPhone: "اضافة هاتف مبدئى",
    IntAddState: "اضافة ولايه / محافظه مبدئيه",
    IntAddZibCode: "اضافة رمز بريدى مبدئى",
    IntAddress: "العنوان",
    VATID: "VAT ID",
    VATPercentage: "VAT نسبة",
    DriverPercentage: "نسبة السائق",
    SupportPhoneNo: "رقم هاتف الدعم",
    SupportEmail: "البريد الالكترونى للدعم",
    UserInitialBalance: "الرصيد الأولي للمستخدم",
    UserInitialFreeTrips: "الرحلات المجانية الأولية للمستخدم",
    MaxUserCredit: "أقصى رصيد للمستخدم",
    DriverInitialFreeTrips: "الرحلات المجانيه الاوليه للسائق",
    DriverInitialBalance: "الرصيد الاولى للسائق",
    DriverInitialRating: "التقييم الأولى للسائق",
    MaxDriverCredit: "أقصى رصيد للسائق",
    CancelationFeePercentage: "نسبة رسوم الإلغاء",
    Arabic: "عربى",
    English: "أنجليزي",
    Urdu: "الأردية",
    Income: "الدخل",
    PleaseFillAllRequiredData: " الرجاء تعبئة جميع البيانات المطلوبة ...",
    IncorrectCode: "شفرة خاطئة",
    IncomeDetailed: "ملخص تقرير الدخل",
    IncomeReportSummary: "تقرير الدخل التفصيلي",
    UnOfficialIncomeSummaryReport: "ملخص الدخل غير الرسمي",
    DriverUnpaidBiadjos: "رحلة النقل غير مدفوعة للسائق",
    home: "الصفحة الرئيسية",
    ContactUs: "أتصل بنا",
    ScheduledBiadjosForCompany: "رحلة النقل المجدولة",
    TransactionsHistoryOfDriver: " تاريخ معاملات السائق",
    DriverLocation: "موقع السائق",
    DriverBiadjosHistory: " تاريج رحلة النقل للسائق",
    DriverScheduledBiadjos: "رحلة النقل مجدولة للسائق ",
    BankAccountEdit: " تعديل الحساب المصرفي",
    DriverProfile: "ملف السائق",
    UserPersonalDetails: " تفاصيل المستخدم الشخصية",
    CompanyUserRegister: " تسجيل مستخدم الشركة",
    EditCompanyProfile: "تعديل ملف الشركة ",
    EditCompanyUser: "تعديل مستخدم الشركة",
    DriverSubmittedOrders: " الطلبات المقدمة من السائق",
    CompanySubmittedOrder: "الطلبات المقدمة من الشركة",
    FollowBiadjoOnMap: "اتبع رحلة النقل علي الخريطة",
    CompanyWallet: "محفظة الشركة",
    CompanyTransactionHistory: "تاريخ معاملات الشركة",
    EditCompanyBankAccountInformation: "تحرير معلومات حساب مصرفي للشركة",
    Deactivate: "تعطيل",
    Activate: "تفعيل",
    YouAreAlreadyloggedin: " قد قمت بتسجيل الدخول بالفعل!",
    ChangeType: " نوع التغيير",
    UnPaiedDriversDetails: "تفاصيل السائقين الغير مدفوع",
    UnPaiedCompaniesDetails: "تفاصيل الشركات الغير مدفوع",
    GenerateReport: "أنشاء تقرير",
    WorkingField:" مجال العمل",
    CompanyID:"Company ID",
    ClientCompanyId: "رقم تعريف شركة العميل",
    FleetCompanyId: "رقم تعريف شركة الأسطول",
    PaiedIncomeTax: "ضريبة الدخل المدفوعة",
    YourSearchingCountry: "بلد البحث هي ",
    personalPicture: "صورة شخصية",
    personalIDFront: "البطاقة الشخصية من الامام",
    personalIDBack: "البطاقة الشخصية من الخلف",
    passport: "جواز السفر",
    driverLicenseFront: "رخصة القيادة الجهة الأمامية",
    driverLicenseback: "رخصة القيادة الجهة الخلفية",
    carRegistration: "رخصة السيارة من الأمام أو كتيب السيارة الصفحة الأولى",
    carRegistrationBack: "رخصة السيارة من الخلف أو كتيب السيارة الصفحة الثانية",
    licensePlateBack: "صورة السيارة باللوحة المعدنية الخلفية",
    licensePlateFront: "صورة السيارة باللوحة المعدنية الأمامية",
    carPicture: "صورة السيارة",
    carInsuranceDoc: "وثيقة تأمين السيارة",
    cargoInsuranceDoc: "وثيقة تأمين البضائع",
    backgroundCheck: "شهادة الخلو من السوابق الجنائية",
    drugTest: "تحليل المخدرات",
    taxIDCard: "البطاقة الضريبية لصاحب السيارة",
    VehicleOwnerFullName: "أسم صاحب المركبة",
    InvalidImageType:
      "أمتداد الصورة غير صالح يرجى التأكد من أن الصورة (png-jpg-tiff)",
    InvalidType: "أمتداد غير صالح",
    WatingForVerificationDriversCount: " عدد السائقين المنتظرين التحقق",
    NONotifications: "قائمة الإشعارات الخاصة بك فارغة",
    UnStartedBiadjosCount: " عدد رحلة النقل لم يبدأ بعد",
    UnInsuredBiadjosCount: "عدد رحلة النقل  غير مؤمن عليه",
    NoDriverFoundBiadojsCount: "رحلة النقل لم يتم العثور على سائق",
    ToDayBiadojsPerTrucktype: "رحلة النقل اليوم لكل نوع شاحنة",
    TodayBiadojsForTodayperStatus: "رحلة النقل اليوم لكل حالة",
    BiadojsPerDayForWeek: "رحلة النقل لأسبوع",
    ClosedBiadojsPerDayForWeek: "رحلة النقل المغلقة لأسبوع",
    UnClosedBiadojsPerDayForWeek: "رحلة النقل الغير المغلقة لأسبوع",
    BiadojsPerTruckTypeForWeek: "رحلة النقل حسب نوع المركبة لأسبوع",
    Youarealreadyloggedin: "قد قمت بتسجيل الدخول بالفعل!",
    DriversPerStatus: "السائقون حسب الحالة",
    BiadjoSchedualeForWeek: "رحلة النقل المجدولة لأسبوع",
    IncomePerDayforWeek: "الدخل الأسبوعي باليوم",
    DriversPerTruckType: "السائقين حسب نوع المركبة",
    TrucksTypes: "أنواع المركبات",
    BiadjoSchedualePerStatusForToday: "رحلة النقل المجدولة لليوم",
    ConflictedBiadjos: "رحلة النقل المتضاربة",
    LoginAttempt: "محاولة تسجيل الدخول",
    Areyousureyouwantdeletethisrecord: "هل أنت متأكد أنك تريد حذف هذا السجل؟",
    Theselectedrecordwassuccessfullydeleted: "تم حذف السجل المحدد بنجاح",
    Deletedrecord: "سجل محذوف",
    InvalidPasswordPleasetryagain:
      " كلمة السر غير مطابقة، برجاء حاول مرة أخرى..!",
    InvalidEmailPleasetryagain:
      " بريد إلكتروني غير صالح ... يرجى المحاولة مرة أخرى ..!",
    SorryYouAccountisNotActive: " عذرًا ، حسابك غير نشط",
    somethingwentwrong: " هناك خطأ ما..!",
    YourCancelationFeesIs: "رسوم الإلغاء الخاصة بك",
    THereIsNoCancelationFees: "لا يوجد رسوم إلغاء",
    Password: "الرقم السري",
    Logout: "خروج",
    YouAreAboutToChangeDriverStatus: "أنت على وشك تغيير حالة حساب السائق",
    YouAreAboutToChangeDriverData: "أنت على وشك تغيير بيانات السائق",
    SessionExpired: "...انتهت الجلسة ، يرجى تسجيل الدخول مرة أخرى",
    VAT: "ضريبة القيمة المضافة",
    YouAreAboutToChangeThisClientStatus: "انت غلي وشك تغير حالة هذا العميل",
    YouAreAboutToChangeThisClientData: "انت علي وشك تغير بيانات هذا العميل",
    SuccessAddNote: "تم اضافة الملاحظة",
    AvailableSizes: "الاشكال المتوفرة",
    Category: "الفئة",
    Select: "اختيار",
    Preview: "معاينة",
    //OpenBedTruck: "شاحنة صندوق مفتوح",
    OpenBedTruck: "بوابير",
    // ClosedBoxTruck: "شاحنة صندوق مغلق",
    ClosedBoxTruck: "قواطر",
    //RefrigeratorTruck: "شاحنة مبردة",
    RefrigeratorTruck: "ديانات",
    lighttransport: "شاحنة نقل خفيف",
    SpecialTruck: "شاحنة خاصة",
    SelectSize: "ًاختر حجم الشاحنة",
    WithDumpster: "شاحنة قلابة",
    LightSize: "خفيف",
    MediumSize: "متوسط 4 متر",
    HeavySize: "ثقيل 6 متر",
    TrailerSize: "مقطورة",
    SemiSize: "شبه مقطورة",
    FlatbedSize: "مسطحة",
    WoodSize: "ناقلة خشب",
    CarsSize: "ناقلة سيارات",
    TowingSizes: "سحب سيارات",
    OpenTruckSized: " شبك - جوانب - مغلقة - قلاب- سطحة -خزان - عازلة - ثلاجة ",
    ClosedTruckSizes: "جوانب - مغلقة - قلاب - سطحة - خزان - عازلة - ثلاجة",
    lighttransportDes: "بدون شبك - شبك - مغلقة - عازلة - ثلاجة ",
    RefrigeratedSizes: "بدون شبك - شبك - مغلقة - سطحة - عازلة - ثلاجة   ",
    SpecialSizes: "مسطحة,ناقلة خشب, \n ناقلة سيارات, سحب سيارات",
    PickupLocation: "موقع الانطلاق",
    DestinationLocation: "موقع الوصول",
    CargoTitle: "عنوان البضائع",
    CargoDescription: "وصف البضائع",
    EstimatedValue: "القيمة التقديرية للحمولة",
    CargoWeight: "وزن البضائع",
    TripDetails: "تفاصيل الرحلة",
    CargoInsurance: "التأمين على البضائع",
    LogNumber: "رقم السجل",
    DateTime: "التوقت",
    StatusLogHistory: "سجل حالة رحلة النقل",
    Agree: "موافق",
    YouAreAboutToRemoveDriver: "انت علي وشك حذف السائق",
    ConfirmRemove: "تاكيد الحذف",
    AddNote: "اضافة ملاحظة",
    WelcomeMsg: "مرحبًا بعودتك ، يرجى تسجيل الدخول إلى حسابك.",
    AccidentPictures: "صور الحادث",
    Submit: "ارسال",
    ReportAccident: "تبليغ عن الحادث",
    ConfirmClose: "تاكيد الاغلاق",
    YouAreAboutToCloseBiadjo: "انت علي وشك اغلاق رحلة النقل",
    CloseAccidentBiadjoNote:
      "برجاء التاكد من الحاق صور الحادث والمستندات المطلوبة",
    CloseAccidentBiadjo: "اغلاق حادثة رحلة النقل",
    AdminUserId: "رمز الادمن",
    ClientId: "رمز العميل",
    AccidentReport: "تقرير الحادث",
    recieptNo: "رقم الايصال",
    NewBalance: "الرصيد الجديد",
    TransactionInfo: "بيانات العملية",
    BankTransferReference: "رقم الحوالة البنكية",
    ChequeNumber: "رقم ألشيك",
    TransactionReferenceForCreditDebitCard: "رقم الحوالة الائتمانية",
    ReceiptNumber: "رقم الأيصال",
    TransactionNo: "رقم العملية",
    Amount: "القيمة",
    PersonalPicture: "صورة شخصية",
    PersonalIDFront: "البطاقة الشخصية من الامام",
    PersonalIDBack: "البطاقة الشخصية من الخلف",
    Passport: "جواز السفر",
    DriverLicenseFront: "رخصة القيادة الجهة الأمامية",
    DriverLicenseback: "رخصة القيادة الجهة الخلفية",
    CarRegistration: "رخصة السيارة من الأمام أو كتيب السيارة الصفحة الأولى",
    CarRegistrationBack: "رخصة السيارة من الخلف أو كتيب السيارة الصفحة الثانية",
    LicensePlateBack: "صورة السيارة باللوحة المعدنية الخلفية",
    LicensePlateFront: "صورة السيارة باللوحة المعدنية الأمامية",
    CarPicture: "صورة السيارة",
    CarInsuranceDoc: "وثيقة تأمين السيارة",
    CargoInsuranceDoc: "وثيقة تأمين البضائع",
    BackgroundCheck: "شهادة الخلو من السوابق الجنائية",
    DrugTest: "تحليل المخدرات",

    PersonalPictureStatus: "حالة الصورة الشخصية",
    PersonalIDFrontStatus: "حالة البطاقة الشخصية من الأمام",
    PersonalIDBackStatus: "حالة البطاقة الشخصية من الخلف",
    PassportStatus: "حالة جواز السفر",
    DriverLicenseFrontStatus: "حالة رخصة القيادة الجهة الأمامية",
    DriverLicensebackStatus: "حالة رخصة القيادة الجهة الخلفية",
    CarRegistrationStatus:
      "حالة رخصة السيارة من الأمام أو كتيب السيارة الصفحة الأولي",
    CarRegistrationBackStatus:
      "حالة رخصة السيارة من الخلف أو كتيب السيارة الصفحة الثانية",
    LicensePlateBackStatus: "حالة صورة السيارة باللوحة المعدنية الخلفية",
    LicensePlateFrontStatus: "حالة صورة السيارة باللوحة المعدنية الأمامية",
    CarPictureStatus: "حالة صورة السيارة",
    CarInsuranceDocStatus: "حلة وثيقة تأمين السيارة",
    CargoInsuranceDocStatus: "حالة وثيقة تأمين البضائع",
    BackgroundCheckStatus: "حالة شهادة الخلو من السوابق الجنائية",
    DrugTestStatus: "حالة تحليل المخدرات",
    Clear: "محو",
    CompanyID: "كود الشركة",
    CompanyInfo: "معلومات الشركة",
    State: "المحافظه",
    CompanyInformation: "معلومات الشركة",
    Id: "رمز",
    FAT1: "مدير",
    FAT2: "موظف",
    ConfirmAndPrint: "تأكيد و طباعة",
    RemoveDriver: "حذف سائق",
    Remove: "حذف",
    CompanyWalletBalance: " محفظة الشركة",
    TransactionsHistory: "سجل التحويلات",
    AddCompanyUser: "أضافة مستخدم إلي الشركة",
    SwitchIndividualToCompany: "تحويل إلى شركة",
    CompanyProfile: "ملف الشركة",
    AddUser: "إضافة مستخدم",
    CompanyUserProfile: "ملف تعريف مستخدم الشركة",
    ALL: "الكل",
    DriverViewPrice: "السماح للسائق بمشاهدة سعر رحلة النقل",
    DriverViewRequests: "السماح للسائق بمشاهدة طلبات رحلة النقل",
    DriverSendOffer: "السماح للسائق بأرسال عروض مالية للرحلة النقل",
    DriverViewBalance: "السماح للسائق بمشاهدة الرصيد",
    DriverViewTransactionHistory: "السماح للسائق بمشاهدة سجل التحويلات المالية",
    CompanyPermissions: "أذونات الشركة",
    YouAreAboutToUpdateBankInfoForAllDrivers:
      "تطبيق البيانات البنكية لكل السائقين",
    ApplyBankInfoToAllDrivers: "تطبيق البيانات البنكية لكل السائقين",
    YouAreAboutToChangeThisUserAccountType:
      "أنت على وشك تغيير نوع حساب المستخدم هذا",
    ZipCode: " الرمز البريدي",
    Address: "العنوان",
    CompanyDetails: "تفاصيل الشركة",
    ShowCompanyDetails: "عرض تفاصيل الشركة ",
    TaxID: "الرقم الضريبي",
    CompanyDrivers: "سائقي الشركة",
    CompanyUsers: "مستخدمي الشركة",
    CompanyUser: "مستخدم الشركة",
    ZibCode: "الرمز البريدي",
    CommercialRegistrationID: "رمز التسجيل التجاري",
    CompanyName: "اسم الشركة",
    FleetCompaniesList: "قائمة شركات الأسطول",
    FleetCompanies: "شركات الأسطول ",
    All: "الكل",
    NewCity: "مدينه جديده",
    SelectedBiadjoNo: "عدد رحلة النقل المحدد",
    Pay: "دفع",
    TotalPrice: "المجموع",
    Total: "العدد",
    DriverTotalPayment: "المجموع المدفوع للسائق",
    FollowDriver: "متابعة السائق",
    PaiedBiadjosTotal: "مجموع رحلة النقل المدفوع",
    PaiedBiadjosCount: "عدد رحلة النقل المدفوع",
    PaiedBiadjosList: "أرقام رحلة النقل المدفوع",
    UnPaiedBiadjosTotal: "مجموع رحلة النقل الغير المدفوع",
    UnPaiedBiadjosCount: "عدد رحلة النقل الغير مدفوع",
    UnPaiedBiadjosList: "أرقام رحلة النقل الغير مدفوع",
    YouAreAboutToPay: "انت علي وشك دفع مبلغ",
    TransactionNumber: "رقم التحويلة",
    TransactionType: "نوع المعاملة",
    Value: "قيمة",
    RecieptNumber: "رقم الإيصال",
    UnPaidDriverList: "السائقين غير المدفوع",
    ViewTransactionsHistory: "عرض سجلات المعاملات",
    TransactionsHistoryOfClient: "سجلات معاملات العميل",
    DriverNotFound: "هذا السائق غير موجود",
    ClientNotFound: "هذا العميل غير موجود",
    UserNotFound: "هذا المستخدم غير موجود",
    UnclosedBiadjos: "رحلة النقل غير مغلق",
    BiadjosNeedInsurance: "تأمين",
    UnStartedBiadjos: "رحلة النقل لم تبدأ",
    ClosedBiadjos: "بيادو مغلق",
    OfferWillSendWithValue: "سوف يتم أرسال العرض بقيمة : ",
    ThisUserAlreadyExist: " هذا المستخدم موجود بالفعل",
    RegisterCreatedSuccessfuly: "تم التسجيل بنجاح",
    printpayment: " طباعة مدفوعات",
    PrintInvoice: "طباعة فاتورة",
    AlreadyExist: "الحساب موجود",
    AddBalance: "إضافة رصيد",
    DeductBalance: "خصم رصيد",
    Cancel: " إلغاء",
    SubmitOffer: "ارسال عرض مالي ",
    BiadjoHistory: "سجلات رحلة النقل",
    ClosingDateFrom: "تاريخ الأقفال من",
    ClosingDateTo: "تاريخ الأقفال إلي",
    UnpaidBiadjos: "رحلة النقل الغير مدفوع",
    BiadjosHistory: "سجلات رحلة النقل",
    ScheduledBiadjos: " رحلة النقل المجدولة",
    Reports: "تقارير",
    BiadjMap: "موقع رحلة النقل",
    Reset: " إعادة تعيين",
    YouAreAboutToResetPasssword: "أنت على وشك إعادة تعيين كلمة المرور",
    YouAreAboutToResetPassswordByEmail:
      " أنت على وشك إعادة تعيين كلمة المرور عن طريق الأيميل",
    Change: "تغيير",
    YouAreAboutToChangeThis: "أنت على وشك التغيير",
    YouAreAboutToSendOrder: " أنت على وشك ارسال طلب مقدم ",

    YouAreAboutToAssignDriver: "أنت على وشك تعين هذا السائق",
    YouAreAboutToUpdateBankInfo:
      "  علي وشك تعديل البيانات الخاصة بالحساب البنكي",
    ConfirmChange: "تأكيد التغيير",
    SubmittedOrderNumber: "رقم الطلب المقدم",
    Register: "تسجيل",
    RememberMe: "تذكرني",
    UpdateImage: "تحميل صورة",
    VehicleColor: "لون المركبة",
    VehicleModel: "موديل المركبة",
    VehicleMake: "صناعة المركبات",
    DriverNo: "رقم السائق",
    vacationMode: "وضع العطلة",
    BankName: "اسم البنك",
    BranchName: "اسم الفرع",
    AccountNumber: "الحساب البنكي",
    Create: "أنشاء حساب",

    City: "المدينة",
    SWIFT: "سويفت",
    BankAddress: "عنوان البنك",
    LicensePlate: "لوحة ترخيص",
    CompletedTrips: "الرحلات المكتملة",
    Documents: "مستندات",
    NationalID: "الرقم القومي",
    DriverLicense: "رخصة قيادة",
    BirthDate: "تاريخ الميلاد",
    Confirm: "تأكيد",
    PaymentDetails: "تفاصيل عملية",
    RegistrationDate: "تاريخ التسجيل",
    AccountType: "نوع الحساب",
    FullName: "الاسم بالكامل",
    FreeTrips: "رحلات مجانية",
    CreditLimit: "حد الائتمان",
    BiadjoDetails: "تفاصيل رحلة النقل",
    AddInsurance: "اضافة تأمين",
    AssignedUserID: "رمز المستخدم",
    AssignedUserName: "اسم المستخدم",
    DriverUserName:"اسم المستخدم للسائق",
    Time_Deff: "فارق التوقيت",
    Assign: "تخصيص",
    AssignBiadjo: "تخصيص رحلة النقل",
    AssignToDriver: "تخصيص سائق",
    ModefideDate: "تاريخ التعديل",
    Drivers: "سائقين",
    Close: "إغلاق",
    CloseBiadjo: "إغلاق رحلة النقل",
    ViewOffers: "عرض العروض",
    Offer: "العرض",
    CustomerId: "رقم العميل",
    ViewSubmittedOrders: "الطلبات المقدمة",
    ViewUserDetails: " تفاصيل العميل ",
    ViewDriverDetails: " تفاصيل السائق",
    EndAddress: "عنوان النهاية",
    EndLocation: "نقطة النهاية",
    EndPlaceId: "رمز نهاية المكان",
    StartAddress: "عنوان البدء",
    StartLocation: "نقطة البداية",
    StartPlaceId: "رمز بدء المكان",
    TripDuration: "مدة الرحلة",
    TripDistance: "مسافة الرحلة",
    SaveChanges: "حفظ",
    Save: "احفظ",

    InsurancePolicyNo: "رقم وثيقة التأمين ",
    SelectLocation: "تحديد الموقع",
    PleaseEnterAllData: "من فضلك ادخل جميع البيانات",
    InsuranceWarning:
      "بإلغاء التأمين انت المسئول الوحيد عن سلامة البضائع أثناء التحميل والتفريغ والنقل",
    CargoValue: "قيمة الشحن",
    CargoType: "نوع البضائع",
    NumberOfTrips: "عدد المحطات",
    Trips: "محطات",
    TripNo: "محطة رقم :",
    NumberOfStops: "عدد المحطات",
    PaymentMethod: "طريقة الدفع أو السداد",
    Biadjo_A_Schedule: "موعد رحلة رحلة النقل أ",
    Biadjo_B_Schedule: "موعد رحلة رحلة النقل ب",
    FollowOnMap: "عرض علي الخريطة",
    UserID: "رمز العميل",
    BiadjoA: "رحلة النقل أ",
    BiadjoB: "رحلة النقل ب",
    BS1: "طلب جديد",
    BS2: "في إنتظار العروض",
    BS3: "المفاضلة بين العروض",
    BS4: "تم الإختيار",
    BS5: "جاري التنفيذ",
    BS6: "وصلت للعميل",
    BS7: "جاري تحميل البضائع",
    BS8: "في الطريق إلى نقطة النهاية",
    BS9: "وصلت لنقطة النهاية",
    BS10: "جاري تفريغ البضائع",
    BS11: "إنتهى التنفيذ",
    BS12: "لم يتم العتور على سائق",
    BS13: "تم الإلغاء",
    BS14: "مغلق",
    BS15: "أبلغ عن حادث",
    BS16: "مغلق بحادث",
    CS1: "جديد",
    CS2: "قيد الإنجاز",
    CS3: "منجز",
    DPS1: "مدفوع",
    DPS2: "غير مدفوع",
    DPS3: "محجوز",
    P1: "المحفظة",
    P2: "كاش",
    P3: "بطاقة إئتمانية",
    P4: "شيك",
    P5: "تحويل بنكي",
    SO1: "جديد",
    SO2: "تم إرسال العرض",
    SO3: "تم رفض العرض ",
    SO4: "تم قبول العرض",
    PS1: "مدفوع",
    PS2: "غير مدفوع",
    PS3: "مؤجل",
    U1: "كجم",
    U2: "طن",
    AS1: "مفعل",
    AS2: "غير مفعل  ",
    AS3: "في الانتظار ",
    AS4: "معلق",
    AS5: "تحميل المستندات ",
    AS6: "في أنتظار التحقق من البيانات  ",
    DS1: "لم يتم تحميل",
    DS2: "تم التحميل",
    DS3: "قبلت",
    DS4: "مرفوضة",
    T1: "خصم رصيد من المحفظة",
    T2: "أضافة رصيد إلى المحفظة",
    T3: "تحويل مصرفي",
    T4: "خصم من بطاقة إئتمانية",
    T5: "إسترجاع مبلغ مالي",
    T6: "نقدي",
    TT1: "شاحنة خفيفة الحمولة",
    TT2: "شاحنة متوسطة 4  متر الحمولة",
    TT3: "شاحنة ثقيلة 6 متر الحمولة",
    TT4: "شاحنة نقل مقطورة",
    TT5: "شاحنة نصف مقطورة",
    TT6: "شاحنة مقطورة مسطحة",
    TT7: "شاحنة خفيفة صندوق",
    TT8: "شاحنة متوسطة 4  متر صندوق",
    TT9: "شاحنة ثقيلة 6 متر صندوق",
    TT10: "شاحنة نصف مقطورة صندوق",
    TT11: "شاحنة خفيفة مبردة",
    TT12: "شاحنة متوسطة 4  متر مبردة",
    TT13: "شاحنة ثقيلة 6 متر مبردة ",
    TT14: "شاحنة نصف مقطورة مبردة",
    TT15: "شاحنة نقل أخشاب",
    TT16: "شاحنة نقل سيارات",
    TT17: "ساحبة سيارات",
    TT23: "سيارة سوزوكي مفتوحة",
    TT24: "سيارة سوزوكي مغلقة",

    TO0: "شاحنة قلابة",
    AT0: "عميل",
    AT1: "مدير",
    AT2: "موظف",
    AT3: "مالك الشركة",

    AAT0: "مشرف متميز",
    AAT1: "الدعم الفني",
    AAT2: "دعم فني",
    AAT3: "مشرف",
    AAT4: "مستثمر",
    AAT5: "مبيعات",
    AAT6: "مدير حساب",
    AAT7: "خدمة عملاء",
    AAT8: "محاسب",
    AAT9: "مدير مستوى 1",
    AAT10: "مدير مستوى 2",
    AAT11: "مدير مستوى 3",
    AAT12: "مدير مستوى 4",
    AAT13: "مدير مستوى 5",
    AAT14: "موظف",
    From: "عن طريق",
    Home: "الصفحة الرئيسية",
    ResendBiadjoRequest: " اعادة ارسال رحلة النقل للسائقين",
    NoDriverFound: "رحلة النقل بدون سائق",
    WaitingForVerficationDrivers: "في انتظار التحقق",
    PaymentID: "معرف عمليات الدفع",
    AssignToMe: "تخصص لي",
    DriverID: "رمز السائق",
    LessThanOrEqual: "اقل من أو يساوي",
    GreaterThanOrEqual: "اكبر من أو يساوي",
    Schedual: "موعد الرحلة",
    ScheduledBiadjo: "مواعيد رحلة النقل",
    Rating: "تقييم",
    UserAccount: "حساب المستخدم",
    UserInfo: "معلومات المستخدم",
    EG_Currency: "جنيه",
    LY_Currency: "دينار",
    CZ_Currency: "يورو",
    US_Currency: "دولار",
    GB_Currency: "باوند",
    Balance: "الرصيد",
    PaymentStatus: "حالة السداد",
    DriverPaymentStatus: "حالة سداد السائق",
    Price: "السعر",
    Driver: "السائق",
    Clients: "العملاء",
    User: "المستخدم",
    BiadjoNumber: "رقم رحلة النقل",
    BiadjoStatus: "حالة رحلة النقل",
    Setting: "الإعدادات",
    Country: "الدوله",
    CountryCode: "رمز الدوله",
    Biadjo: "رحلة النقل",
    ClosingDate: "تاريخ الاغلاق",
    CreationDate: "تاريخ الانشاء",
    BiadjosPerStatus: "رحلة النقل حسب الحالة",
    Scheduled: "المجدولة",
    BiadjoTitle: "لوحة تحكم رحلة النقل",
    Edit: "تعديل",
    Back: "عودة للخلف",
    Status: "الحالة",
    PaymentGateway: "بوابة الدفع",
    CancelBiadjo: "الغاء رحلة النقل",
    ScheduleConflict: "جدولة النزاع",
    Dashboard: "لوحة القيادة",
    FirstName: "الاسم الاول",
    LastName: "اللقب",
    PhoneNumber: "رقم الهاتف",
    DriverBalance: "رصيد السائق",
    AccountStatus: "حالة الحساب",
    EditUser: "تعديل المستخدم",
    VacationMode: "وضع العطلة",
    VehicleType: "نوع السيارة",
    VehicleOption: "خيار السيارة",
    UserBalance: "رصيد المستخدم",
    Filters: "فلترة",
    Actions: "أجراءات",
    VATREQ: "فاتورة ضريبية",
    KMBiadjo: "رحلة النقل بالكيلومتر",
    AddDriverPriceandAssignDrivertoKMBiadjo: "تخصيص سائق بالكيلومتر",
    BiadjoAcceptedKMPrice: "تم قبول رحلة النقل بسعر الكيلومتر",
    DriverPrice: "سعر السائق",
    trueBool: "نعم",
    falseBool: "لا",
    KMOffer: "سعر بالكيلومتر",
    showNoDataAvailable: "لا توجد بيانات متاحة ... يرجى المحاولة مرة أخرى",
    NoNotesAvailable: "لا توجد ملاحظات",
    showError: "حدث خطأ ما. أعد المحاولة من فضلك",
    Update: "تحديث",
    showUpdate: "تم التحديث بنجاح ...",
    Error: "خطأ",
    ResetPassword: "إعادة تعيين كلمة المرور",
    showCheckEmail: "يرجى التحقق من بريدك الإلكتروني لكلمة المرور الجديدة ...",
    showPasswordChanged: "تم تغيير كلمة السر...",
    ConflictedBiadjo: "رحلة النقل متعارض",
    BodyConflictedBiadjo: "يوجد رحلة النقل متعارضة يرجى حله في أقرب وقت ممكن ",
    AssignBiadjoToDriver: "تخصيص رحلة النقل لسائق",
    StartCity: "مدينة الانطلاق",
    EndCity: "مدينة الوصول",
    StartState: "محافظة الانطلاق",
    EndState: "محافظة الوصول",
    Insurance: "التأمين",
    InsuranceValue: "قيمة التأمين",
    ClientInformation: "معلومات العميل",
    BankAccountInfo: "معلومات الحساب البنكي",
    VehicleInfo: "معلومات المركبة",
    DriverInfo: "معلومات السائق",
    Height: "الارتفاع",
    Length: "الطول",
    Width: "العرض",
    StartCountry: "بلد الانطلاق",
    StartCountryCode: "كود بلد الانطلاق",
    EndCountry: "بلد الوصول",
    EndCountryCode: "كود بلد الوصول",
    FindNearByDrivers: "إيجاد السائقين القريبين",
    Apps: "Apps",
    Todo: "Machen",
    Chat: "Plaudern",
    Email: "البريد الالكتروني",
    Duration: "المدة",
    Distance: "المسافة",
    Calendar: "التقويم",
    Date: "تاريخ",
    Login: "تسجيل دخول",
    ForgotPassword: "نسيت كلمة المرور؟",
    NotAuthorized: "غير مخول",
    Maintenance: "صيانة",
    Profile: "الصفحة الشخصية",
    Invoice: "Rechnung",
    FAQ: "FAQ",
    Search: "بحث",
    Import: "استيراد",
    Export: "تصدير",
    Others: "الآخرين",
    Name: "الاسم",
    WithdrawalAndPayment: "طرق الدفع و السحب"
  }
};
